import React from "react";
import Ionicons from '@expo/vector-icons/Ionicons';
import { SafeAreaView , 
          StyleSheet , 
          FlatList,
          Text , 
          ScrollView,
          Image,
          View , 
          TouchableOpacity , 
          TextInput} from "react-native";
import db from "../db/Firestore";
import { Timestamp } from "firebase/firestore";

const styles = StyleSheet.create({
    container :{
        flex:1,
        alignItems: 'center',
        justifyContent:'center'
    },
    box :{
        alignItems: 'center',
        justifyContent:'center',
        width: '80%',
        maxWidth: 500
    },
    titulo :{
        fontSize: 25,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 15,
    },
    buttonComentar: {
        width:"45%",
        textAlign: 'center',
        marginHorizontal: 'auto',
        padding: 15,
        borderRadius: 15,
        backgroundColor: '#0400b6',
        marginTop: 20,
    },
    buttonRegresar: {
        width:"45%",
        textAlign: 'center',
        marginHorizontal: 'auto',
        padding: 15,
        borderRadius: 15,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#0400b6',
        backgroundColor: '#ffffff',
        marginTop: 20,
    },
    buttonText: {
        color: '#ffffff',
        textAlign: 'center',
    },
    buttonRegresarText: {
        color: '#0400b6',
        textAlign: 'center',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginBottom: 5,
        width: '100%',
    },
    puntajeBox: {
        flexDirection: 'row',
        marginBottom: 10,
        justifyContent: "space-between",
    }
});

function Review({route , navigation}){

    const [completado , SetCompletado] = React.useState(0);
    const [comentario , SetComentario] = React.useState({
        nombre : route.params.nombre,
        userId : route.params.userId,
        productoId: route.params.productoId,
        tiendaId: route.params.tiendaId,
        userTiendaId: route.params.userTiendaId,
        fechaCreado: Timestamp.fromDate(new Date()),
        comentario: '',
        puntos: 0
    });

    function addComentario(comentario , navigation)
    {console.log(comentario)
        if(comentario.comentario == '')
        {
            navigation.navigate('Notificacion' , {
                type: 'warning',
                title: 'Datos Requeridos',
                message: 'en campo comentario es obligatorio.'
            })

            return true
        }
        
        if(comentario.comentario.length < 6)
        {
            navigation.navigate('Notificacion' , {
                type: 'warning',
                title: 'Datos Requeridos',
                message: 'en campo comentario debe tener mínimo 6 caracteres.'
            })

            return true
        }
        
        if(comentario.puntos < 1)
        {
            navigation.navigate('Notificacion' , {
                type: 'warning',
                title: 'Datos Requeridos',
                message: 'Debe colocar una puntuación para su comentario.'
            })

            return true
        }

        db.collection('reviews')
            .add(comentario)
            .then(() => {
                SetCompletado(1)
            });
    }

    return (
       
            <View style={styles.container}>
                {completado ? <>
                    <View style={styles.box}>
                        <Text style={styles.titulo}>
                            <Ionicons name="checkmark-circle" size={50} color="#008f39"></Ionicons>
                        </Text>
                        <Text style={styles.titulo}>Gracias por su comentario!</Text>
                        <TouchableOpacity style={styles.buttonRegresar} onPress={() => navigation.goBack()}>
                            <Text style={styles.buttonRegresarText}>Regresar a compras</Text>
                        </TouchableOpacity>
                    </View>
                </> : <>
                    <View style={styles.box}>
                        <Text style={styles.titulo}>Tu opinión es importante para nosotros</Text>

                        <View style={styles.puntajeBox}>
                            <TouchableOpacity onPress={e => {
                                    SetComentario({
                                    ...comentario,
                                    puntos: 1
                                    });
                                }}>
                                <Text>
                                    <Ionicons name="star" size={30} color={ comentario.puntos >= 1 ? '#FFFF00' : '#9b9b9b'}></Ionicons>
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={e => {
                                    SetComentario({
                                    ...comentario,
                                    puntos: 2
                                    });
                                }}>
                                <Text>
                                    <Ionicons name="star" size={30} color={ comentario.puntos >= 2 ? '#FFFF00' : '#9b9b9b'}></Ionicons>
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={e => {
                                    SetComentario({
                                    ...comentario,
                                    puntos: 3
                                    });
                                }}>
                                <Text>
                                    <Ionicons name="star" size={30} color={ comentario.puntos >= 3 ? '#FFFF00' : '#9b9b9b'}></Ionicons>
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={e => {
                                    SetComentario({
                                    ...comentario,
                                    puntos: 4
                                    });
                                }}>
                                <Text>
                                    <Ionicons name="star" size={30} color={ comentario.puntos >= 4 ? '#FFFF00' : '#9b9b9b'}></Ionicons>
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={e => {
                                    SetComentario({
                                    ...comentario,
                                    puntos: 5
                                    });
                                }}>
                                <Text>
                                    <Ionicons name="star" size={30} color={ comentario.puntos >= 5 ? '#FFFF00' : '#9b9b9b'}></Ionicons>
                                </Text>
                            </TouchableOpacity>
                        </View>

                        <TextInput 
                            style={styles.input}
                            placeholder="Comentario"
                            onChangeText={val => {
                                SetComentario({
                                ...comentario,
                                comentario: val
                                });
                            }}
                            value={comentario.comentario}
                            multiline
                            rows={4}
                        />

                        <View style={{flexDirection: 'row' , width: '100%'}}>
                            <TouchableOpacity style={styles.buttonRegresar} onPress={() => navigation.goBack()}>
                                <Text style={styles.buttonRegresarText}>Regresar</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.buttonComentar} onPress={() => addComentario(comentario , navigation)}>
                                <Text style={styles.buttonText}>Aceptar</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </>}
            </View>
       
    );
}

export default Review;