import React , { useState, useRef } from "react";
import { View , TouchableOpacity , Image , Button, Text , StyleSheet, Animated } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import { useRoute } from '@react-navigation/native';
import Firebase from "../db/Firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { doc, getDoc , updateDoc} from "firebase/firestore";

function LogoutFn(reload)
{
    AsyncStorage.setItem('authToken', '');
    AsyncStorage.setItem('tipoUser', '');
    // window.location.reload(false);
    reload('' , '' , '')
}

const styles = StyleSheet.create({
    fondo:{
        // backgroundColor: '#ffffff',
        position: 'fixed',
        top:0,
        left:0,
        width: '60%',
        height: '100vh',
        transition: 'all ease .5s',
        // transform : 'translateX(-100%)',
    },
    container : {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',     
        flexDirection: 'col',
        justifyContent: 'start', // space-between
        backgroundColor: '#ffffff',
        // borderTopLeftRadius: 30,
        // borderTopRightRadius: 30,
        height: '100vh',
        shadowColor: '#171717',
        shadowOffset: {width: 0, height: -5},
        shadowOpacity: 0.2,
        shadowRadius: 50,
    },
    buttonFix: {
        textAlign: 'center',
        justifyContent: 'center',
        padding: 20,
        flexDirection: 'row',
    },
    buttonFixName: {
        textAlign: 'center',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'row',
        padding: 20,
        backgroundColor: 'rgb(34,127,163)',
    },
    textoName:{
      fontSize: 17,
      color: '#ffffff',
      fontWeight: 'bold',
      textAlign: 'center',
      justifyContent: 'center',
      // padding: 20,
    },
    imgName:{
      width: 50,
      // padding: 20,
      marginRight: 10,
      height:50,
      borderRadius: 50,

    },
    buttonFixActivo: {
        textAlign: 'center',
        justifyContent: 'center',
        padding: 20,
        flexDirection: 'row',
        // backgroundColor: '#ffffff',
        // borderRadius: '50%',
        borderTopStartRadius: 20,
        borderTopEndRadius: 20,
    },
    iconItem: {
        // marginLeft : 'auto',
        marginRight : 10,
        color: '#808080',
    },
    iconItemActivo: {
        // marginLeft : 'auto',
        marginRight : 10,
        color: '#000000',
    },
    iconTexto: {
        color: 'rgb(34,127,163)',
        fontSize: 20,
    },
    iconTextoCerrar: {
        color: '#808080',
        fontSize: 20,
        marginTop: 'auto',
    },
    iconTextoActivo: {
        color: '#0400b6',
        fontSize: 20,
    },
    buttonToggle:{
      position: 'absolute',
      top: 10,
      left: 10,
      transform: 'translateX(100%)',
    }
});

let authToken = null;
let docRef = null;
let docSnap = null;
let userRef = null;

async function authFn(){
  authToken = await AsyncStorage.getItem('authToken');

  docRef = authToken == '' || authToken == null  
      ? null 
      : doc(Firebase , 'users' , authToken);
  docSnap = authToken == '' || authToken == null  
      ? null 
      : await getDoc(docRef);
  userRef = authToken == '' || authToken == null  
      ? {} 
      : docSnap.data();

  return userRef;
}

export default function Botones({navigation , toggleMenu , menuPos , reload }){
   
    const route = useRoute();
    const routeName = route.name;
    const [isVisible, setIsVisible] = useState(false);
    const [loaded, setLoaded] = useState(0);
    const [userData, setUserData] = useState(null);
    console.log(userData);
    if(!loaded)
    {
      authFn().then((userData) => {
        console.log(userData)
          if(userData && userData != '')
          {
            setUserData(userData)
            setLoaded(1);
          }
      })
    }
    // console.log(menuPos);
    // const [menuPos, setMenuPos] = useState(menuPos);
    
    // function toggleMenu()
    // {
    //   if(menuPos == 'translateX(-100%)')
    //   {
    //     setMenuPos('translateX(0%)');
    //   }else{
    //     setMenuPos('translateX(-100%)');
    //   }
    // }

    return (
        <View style={{...styles.fondo ,
                      transform:  menuPos  }}>
        
          
          <View style={styles.container} >
              <TouchableOpacity  onPress={toggleMenu} style={styles.buttonFixName}>
                    {userData && userData.image ?
                    <Image 
                        source={{ uri: userData.image }} 
                        style={styles.imgName} 
                    /> : <></>}
                  <Text style={styles.textoName}>
                    {userData ? userData.nombre : ''}   
                  </Text>
              </TouchableOpacity>

              <TouchableOpacity style={ routeName == 'ProductosListaAuth' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('ProductosListaAuth')}>
                {/* <Ionicons style={routeName == 'ProductosListaAuth' ? styles.iconItemActivo : styles.iconItem} name="home" size={20} color="#0400b6"></Ionicons> */}
                <Text style={routeName == 'ProductosListaAuth' ? styles.iconTextoActivo : styles.iconTexto}>Inicio</Text>
              </TouchableOpacity>
              
              {/* <TouchableOpacity style={ routeName == 'Favoritos' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('Favoritos')}>
                <Ionicons style={routeName == 'Favoritos' ? styles.iconItemActivo : styles.iconItem} name="heart" size={20} color="#0400b6"></Ionicons>
                <Text style={routeName == 'Favoritos' ? styles.iconTextoActivo : styles.iconTexto}>Favoritos</Text>
              </TouchableOpacity> */}

              {/* <TouchableOpacity style={routeName == 'Carrito' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('Carrito')}>
                <Ionicons style={routeName == 'Carrito' ? styles.iconItemActivo : styles.iconItem} name="cart" size={20} color="#0400b6"></Ionicons>
                <Text style={routeName == 'Carrito' ? styles.iconTextoActivo : styles.iconTexto}>Carrito</Text>
              </TouchableOpacity> */}

              <TouchableOpacity style={routeName == 'Compras' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('Compras')}>
                {/* <Ionicons style={routeName == 'Compras' ? styles.iconItemActivo : styles.iconItem} name="pricetags" size={20} color="#0400b6"></Ionicons> */}
                <Text style={routeName == 'Compras' ? styles.iconTextoActivo : styles.iconTexto}>Compras</Text>
              </TouchableOpacity> 
              
              <TouchableOpacity style={routeName == 'Chat' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('Chat')}>
                {/* <Ionicons style={routeName == 'Chat' ? styles.iconItemActivo : styles.iconItem} name="chatbox" size={20} color="#0400b6"></Ionicons> */}
                <Text style={routeName == 'Chat' ? styles.iconTextoActivo : styles.iconTexto}>Chat</Text>
              </TouchableOpacity> 

              <TouchableOpacity style={routeName == 'Perfil' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('Perfil')}>
                {/* <Ionicons style={routeName == 'Perfil' ? styles.iconItemActivo : styles.iconItem} name="person" size={20} color="#0400b6"></Ionicons> */}
                <Text style={routeName == 'Perfil' ? styles.iconTextoActivo : styles.iconTexto}>Perfil</Text>
              </TouchableOpacity>
              
              <TouchableOpacity style={{...styles.buttonFix , marginTop: 'auto'}} onPress={() => LogoutFn(route.params.reload)}>
                {/* <Ionicons style={routeName == 'Perfil' ? styles.iconItemActivo : styles.iconItem} name="person" size={20} color="#0400b6"></Ionicons> */}
                <Text style={styles.iconTextoCerrar}>Cerrar Sesión</Text>
              </TouchableOpacity>
          </View>
        </View>
    );
}