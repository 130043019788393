import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAQmC_rxs8DXyf_8SMggkngMNFVARsiCfw",
    authDomain: "altoque-22217.firebaseapp.com",
    projectId: "altoque-22217",
    storageBucket: "altoque-22217.appspot.com",
    messagingSenderId: "228751463016",
    appId: "1:228751463016:web:719bdf64df7d12f0cfe1dd",
    measurementId: "G-C28W184XBJ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = firebase.firestore();

export default db;
