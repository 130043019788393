import React from "react";
import Ionicons from '@expo/vector-icons/Ionicons';
import { SafeAreaView , 
          StyleSheet , 
          FlatList,
          Text , 
          ScrollView,
          Image,
          View , 
          TouchableOpacity , 
          TextInput} from "react-native";
import Firebase from "../db/Firebase";
import { doc, setDoc , getDocs , addDoc , collection , Timestamp , query , where, Firestore} from "firebase/firestore";

          
import Botones from "../components/Botones";


const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#ececec',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputBuscar: {
        // padding: 14,
        // borderRadius: 5,
        // marginBottom: 5,
        // marginTop: 15,
        paddingLeft: 10,
        marginLeft: 'auto',
        width: '90%',
    },
    button: {
        // display: 'inline-block',
        flex : 1,
        borderStyle: 'solid',
        borderColor: '#8a8a8a',
        borderWidth: 1,
        paddingTop: 4,
        paddingRight: 10,
        paddingBottom: 4,
        paddingLeft: 10,
        borderRadius: 5,
        marginRight: 4,
        marginLeft: 4,
    },
    buttonText:{
      color: '#8a8a8a',
    },
    containerSafeArea: {
      flex: 1,
      marginTop: 0,
    },
    gridLista : {
      width: '80%',
      // flexWrap: 'wrap',
      justifyContent: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      flexGrow: 1,
    },
    itemLista : {
      width: '100%',
      justifyContent: 'center',
      margin: 'auto',
      marginTop: 10,
    },
    imagenLista: {
      width: '100%',
      height: 170,
      borderRadius: 15,
      marginTop: 5,
      marginBottom: 5,
    },
    titulo: {
      fontWeight: 'bold',
      fontSize: 23.5,
      margin: 20,
    },
    tituloLista: {
      fontWeight: 'bold',
    },
    descripcionLista: {
      marginBottom: 15,
    },
    botonVer: {
      textAlign: 'right',
    },
    botonVerTexto: {
      color: '#0400b6',
      fontWeight: 'bold',
    },
    buttonContainer: {
      // flex: 1,
      // flexDirection: 'row',
      width: '80%',
    },
    searchTop: {
      flexDirection: 'row',
      width: '80%',
      justifyContent: 'space-between',
      marginTop: 20,
    },
    searchTopInput: {
      flexDirection: 'row',
      width: '90%',
      borderStyle: 'solid',
      borderColor: '#0400b6',
      borderWidth: 1,
      padding: 5,
      borderRadius: 5,
      marginTop: 'auto',
      marginBottom: 'auto',

    },
    searchIcon: {
        // marginTop: 15,
        // width: 20,
        padding: 5,
        borderRightColor: '#0400b6',
        borderRightWidth: 1,
        borderStyle: 'solid',
    },
    heartIcon: {
        padding: 10,
        backgroundColor: '#0400b6',
        borderRadius: 5,
        marginLeft: 5,
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    tituloTienda : {
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#ffffff',
      fontSize: 15,
      marginBottom: 15,
    },
    textTienda : {
      color: '#ffffff',
    },
    imgTienda : {
      height: 50,
      width: '100%',
    },
    containerTitulo: {
      padding: 20,
      backgroundColor: '#0400b6',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '80%',
      flexDirection: 'row',
      borderRadius: 20,
      marginTop: 40,
      marginBottom: 10,
    },
    containerDescripcion: {
      // padding: 30,
      width: '80%',
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'justify',
    },
  });

  const DATA = [
    // {
    //   picture: 'https://www.entornoestudiantil.com/wp-content/uploads/2018/03/retail-carrito-compra.jpg',
    //   name: 'Producto1',
    //   about: 'Producto para la venta'
    // },
    // {
    //   picture: 'https://www.entornoestudiantil.com/wp-content/uploads/2018/03/retail-carrito-compra.jpg',
    //   name: 'Producto1',
    //   about: 'You don’t have to use many state variables. State variables can hold objects and arrays just fine, so you can still group related data together. However, unlike this.setState in a class, updating a state variable always replaces it instead of merging it.'
    // },
  ];

    
  const Item = ({item , navigation}) => (
    <View style={styles.itemLista}>
      <TouchableOpacity onPress={() => navigation.navigate('Producto' , {
        productoId : item.id
      })}>
        <Image source={{ uri: item.image }} style={styles.imagenLista}/>
      </TouchableOpacity>
      
      <Text style={styles.tituloLista}>{item.nombre}</Text>
      <Text style={styles.descripcionLista}>{item.descripcion && item.descripcion.substring(0,100)}...</Text>

      <TouchableOpacity style = {styles.botonVer}>
        <Text style = {styles.botonVerTexto}>Ver Productos <Ionicons name="arrow-forward" size={20} color="#0400b6"></Ionicons></Text>
      </TouchableOpacity>
    </View>
  );
  
  
  const Categorias = ({item}) => (
    <TouchableOpacity 
          style={styles.button}>
            <Text style={styles.buttonText}>{item.text}</Text>
    </TouchableOpacity>
  );

  async function getProductos(userId)
  {
      const q = query(collection(Firebase, "productos") , where('userId' , '==' , userId));

      const querySnapshot = await getDocs(q);
      
      DATA.length = 0;
      
      querySnapshot.forEach((doc) => {
          DATA.push({
              id: doc.id,
              descripcion: doc.data().descripcion,
              nombre:doc.data().nombre,
              image:doc.data().image,
              precio:doc.data().precio,

          });
          // //console.log(doc.id, " => ", doc.data());
          //console.log(DATA.length)
      });

      return DATA;
  }
  
// //console.log(use)
function Tienda({route, navigation}){
      const [loaded , SetLoaded] = React.useState(0);
      // getProductos(route.params.userId);
      if(route.params.userId)
      {
        getProductos(route.params.userId).then(() => {
          SetLoaded(1);
        });
      }
      return(  
        <View style={{flex: 1}}>
          <ScrollView style={styles.containerSafeArea}>
            <View style={styles.container}>
              
              <View style={styles.searchTop}>
                
                <View style={styles.searchTopInput}>
                  <Ionicons style={styles.searchIcon} name="search" size={20} color="#0400b6"/>
                  <TextInput
                    placeholder="Buscar productos o tiendas"
                    style={styles.inputBuscar}
                  />
                </View>
                <TouchableOpacity>
                  <Ionicons style={styles.heartIcon} name="heart" size={20} color="#ffffff"/>
                </TouchableOpacity>
              </View>

            
                <View style={styles.containerTitulo}>
                  <View style={{width: '50%'}}>
                    <Text style={ styles.tituloTienda }>{route.params.tiendaNombre}</Text>
                  </View>
                  <View style={{width: '40%'}}>
                    <Image style={ styles.imgTienda } source={{ uri: 'https://cdn.forbes.com.mx/2022/06/super-tienda-e1654633021697-640x360.jpg' }}/>
                  </View>
                </View>
                <View style={styles.containerDescripcion}>
                  <Text>{route.params.tiendaDescripcion}</Text>
                </View>


                {/* <FlatList
                  data={[{ id: 1 , text : 'Todo'} , { id:2 ,  text : 'Uno'}]}
                  keyExtractor={item => item.id}
                  style={styles.buttonContainer}
                  horizontal={true}
                  renderItem={({item}) => <Categorias item={item} />}
                /> */}

                {loaded ? <>
                  <FlatList
                  data={DATA}
                  renderItem={({item}) => <Item item={item} navigation={navigation}/>}
                  keyExtractor={item => item.id}
                  contentContainerStyle={styles.gridLista}
                  style={{width:'100%'}}
                />
                
                </> : <></>}
                
            
              
            </View>
          </ScrollView>
          <Botones navigation={navigation}/>
        </View>
      );
}

export default Tienda;