import React from "react";
import Ionicons from '@expo/vector-icons/Ionicons';
import { SafeAreaView , 
          StyleSheet , 
          FlatList,
          Text , 
          ScrollView,
          Image,
          ImageBackground,
          View , 
          TouchableOpacity , 
          TextInput} from "react-native";
import db from "../db/Firestore";
import { Timestamp } from "firebase/firestore";
import AsyncStorage from '@react-native-async-storage/async-storage';
import BotonesTienda from "../components/BotonesTienda";

const styles = StyleSheet.create({
    container :{
        flex:1,
        alignItems: 'center',
        justifyContent:'center'
    },
    containerSafeArea: {
        flex: 1,
        marginTop: 50,
        // minHeight: '100vh',
      },
      imageFondo: {
        flex: 1,
      },
      chatroom: {
        flexDirection: 'row' , 
        justifyContent: 'space-between',
        width: '100%',
        padding: 9,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#0400b6',
        marginBottom: 8,
        borderRadius: 15,
      },
      boxMessage: {
        flexDirection : 'row',
        width: '80%',
        marginRight: 'auto',
        marginLeft: 'auto',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderColor: '#ffffff',
        borderTopWidth: 1,
        borderStyle: 'solid',
      },
      inputMessage: {
        // backgroundColor: '#ffffff',
        width: '85%',
        color: '#ffffff',
      },
      buttonMessage: {
        // backgroundColor: '#ffffff',
        width: '10%',
      },
      mensajeOtro:{
        backgroundColor: '#ffffff',
        maxWidth: '95%',
        marginBottom: 5,
        padding: 10,
        marginLeft: 'auto',
        borderWidth: 1,
        borderColor: '#0400b6',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
      },
      mensajePropio:{
        backgroundColor: '#ececec',
        maxWidth: '95%',
        marginBottom: 5,
        padding: 10,
        marginRight: 'auto',
        borderWidth: 1,
        borderColor: '#8a8a8a',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 15,
      },
      titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
        color: '#ffffff',
      },
});

const ROOMS = [];
const MENSAJES = [];
let authToken = null;

async function authFn(){
    authToken = await AsyncStorage.getItem('authToken');

    return authToken;
}

async function getRooms()
{   
    ROOMS.length = 0;

    await db.collection('rooms')
            .where('tiendaId' , '==' , authToken)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    ROOMS.push({
                    id : doc.id,
                    ... doc.data()
                    })
                    // //console.log(doc.data())
                });
            });
}

async function getMensajes(roomId)
{   
    MENSAJES.length = 0;

    await db.collection('mensajes')
            .where('roomId' , '==' , roomId)
            .orderBy('fechaCreado')
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    MENSAJES.push({
                    id : doc.id,
                    ... doc.data()
                    })
                    // //console.log(doc.data())
                });
            });
    // db.collection('mensajes')
    //         .where('roomId' , '==' , roomId)
    //         .orderBy('fechaCreado')
    //         .onSnapshot((querySnapshot) => {
    //             // MENSAJES.length = 0;

    //             querySnapshot.forEach((doc) => {
    //                 MENSAJES.push({
    //                     id : doc.id,
    //                     ... doc.data()
    //                 })
    //                 // //console.log(doc.data())
    //             });
    //         });
}

authFn();

function ChatTienda({route , navigation}){

    const [loaded , SetLoaded] = React.useState(0);
    const [vista , SetVista] = React.useState('lista');
    const [mensaje , SetMensaje] = React.useState('');
    const [room , SetRoom] = React.useState(route.params.room);

    const [menuPos, setMenuPos] = React.useState('translateX(-100%)');
    // console.log(menuPos);
    function toggleMenu()
    {
        if(menuPos == 'translateX(-100%)')
        {
        setMenuPos('translateX(0%)');
        }else{
        setMenuPos('translateX(-100%)');
        }
    }

    const Item = ({item , navigation}) => (
        <View>
            <TouchableOpacity style={styles.chatroom} onPress={() => abrirRoom(item.id)}>
                <Text>{item.nombre} - { item.nombreTienda }</Text>
            </TouchableOpacity>
        </View>
    );
    
    const ItemMensaje = ({item , navigation}) => (
        <View>
            <View style={item.userId == authToken ? styles.mensajePropio :  styles.mensajeOtro}>
                <Text>{item.mensaje}</Text>
            </View>
        </View>
    );

    function enviarMensaje()
    {
        const mensajeData = {
            roomId: room,
            userId: authToken,
            mensaje: mensaje,
            fechaCreado  :  Timestamp.fromDate(new Date()),
        };

        db.collection('mensajes')
            .add(mensajeData)
            .then(() => {
                getMensajes(room).then(() => {
                    SetMensaje('');
                });
            })
    }

    function abrirRoom(roomId)
    {
        getMensajes(roomId).then(() => {
            SetVista('chat');
            SetRoom(roomId);
        });
    }
    
    function resetForm()
    {
        getRooms().then(() => {
            SetMensaje('')
            SetVista('lista')
        });
    }
   
    if(loaded == 0)
    {   
        getRooms().then(() => {
            SetLoaded(1)
        });
    }

    return (
        <View style={{flex: 1 , backgroundColor: 'rgb(34,127,163)'}}>
            <ImageBackground source={require('../img/mates.svg')} resizeMode="cover" style={styles.imageFondo}>
                <ScrollView style={styles.containerSafeArea}>

                    <View style={{ width: '80%', marginHorizontal: 'auto', }}>
                        <View style={{flexDirection: 'row' , alignItems: 'center', justifyContent: 'space-between' , width: '80%' , marginRight: 'auto' , marginLeft : 'auto'}}>
                                <Text style={styles.titulo}>Salas de Chat</Text>
                                <TouchableOpacity onPress={toggleMenu}>
                                    <Ionicons style={styles.heartIcon} name="menu" size={21} color="#ffffff"/>
                                </TouchableOpacity>
                        </View>
                        {vista == 'lista' && <>
                            <FlatList
                                data={ROOMS}
                                renderItem={({item}) => <Item item={item} navigation={navigation}/>}
                                keyExtractor={item => item.id}
                                contentContainerStyle={styles.gridLista}
                                style={{width:'100%' , marginTop : 10}}
                                // numColumns={2}
                                />                        
                        </>}
                        
                        {vista == 'chat' && <>
                            <TouchableOpacity style={styles.buttonPequeno} onPress={() => {
                                    resetForm()
                                }}>
                                <Text style={styles.buttonPequenoTexto} ><Ionicons name="arrow-back" size={20} color="#0400b6"></Ionicons> Volver al listado</Text>
                            </TouchableOpacity>   
                            <FlatList
                                data={MENSAJES}
                                renderItem={({item}) => <ItemMensaje item={item} navigation={navigation}/>}
                                keyExtractor={item => item.id}
                                contentContainerStyle={styles.gridLista}
                                style={{width:'100%' , marginTop : 10}}
                                // numColumns={2}
                                />   
                                           
                        </>}
                        
                    </View>

                </ScrollView>

                {vista == 'chat' && <>
                    <View style={styles.boxMessage}>
                            <TextInput 
                                style={styles.inputMessage}
                                multiline
                                numberOfLines={3}
                                placeholder="Mensaje..."
                                onChangeText={SetMensaje}
                                value={mensaje}
                            />    
                            <TouchableOpacity style={styles.buttonMessage} onPress={() => enviarMensaje()}>
                                <Text>
                                    <Ionicons name="send" size={20} color="#ffffff"></Ionicons>
                                </Text>
                            </TouchableOpacity>
                    </View>       
                </>}               
                <BotonesTienda navigation={navigation} toggleMenu={toggleMenu} menuPos={menuPos}/>
            </ImageBackground>
        </View>
    )
}

export default ChatTienda;