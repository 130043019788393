import React from "react";
import { StyleSheet ,View ,FlatList , Image , TextInput , ScrollView , TouchableOpacity , Text  } from "react-native";
// import BotonesTienda from "../components/BotonesTienda";
import Ionicons from '@expo/vector-icons/Ionicons';
import { collection, getDocs , updateDoc , query , where , deleteDoc , doc} from "firebase/firestore";
import BotonesAdmin from "../components/BotonesAdmin";
import Firebase from "../db/Firebase";

const styles = StyleSheet.create({
    container:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    itemLista: {
        padding: 4
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#0400b6',
        borderWidth: 1,
        color: '#0400b6',
        borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutlineTexto: {
        color: '#0400b6',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        backgroundColor: '#ececec',
        borderWidth: 0,
        // borderRadius: 30,
        marginBottom: 10,
        width: '80%',
        // maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
        color: '#ffffff',
      },
    logo: {
        width: 100,
        height: 187,
        marginBottom: 80,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      botonLista: {
        flexDirection: 'row' , 
        justifyContent: 'space-between',
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 9,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#0400b6',
        marginBottom: 8,
        
      },
      botonListaTexto: {
        color: '#0400b6',
      },    
      botonListaTextoEliminar: {
        color: '#FF0000',
      },    
      botones: {
        flexDirection: 'row' , 
        justifyContent: 'space-between',
        marginHorizontal: 'auto',
        marginVertical: 25,
        padding: 9,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#0400b6',
        marginBottom: 8,
        
      },
      botonesEliminar: {
        flexDirection: 'row' , 
        justifyContent: 'space-between',
        marginHorizontal: 'auto',
        marginVertical: 25,
        padding: 9,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#FF0000',
        marginBottom: 8,
        
      },
});


const DATA = [];


async function getUsers(){
    
    const querySnapshot = await getDocs( query(collection(Firebase, "users") , where('tipo' , '==' , 1)) );
    
    DATA.length = 0;

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      DATA.push({
        id: doc.id, ...doc.data(),
      });
   
    });
} 

export default function UsersAdmin({navigation}){
    const [loaded , SetLoaded] = React.useState(0);
    const [editar , SetEditar] = React.useState(0);
    const [usuario , SetUsuario] = React.useState({});

    const [menuPos, setMenuPos] = React.useState('translateX(-100%)');
    // console.log(menuPos);
    function toggleMenu()
    {
        if(menuPos == 'translateX(-100%)')
        {
        setMenuPos('translateX(0%)');
        }else{
        setMenuPos('translateX(-100%)');
        }
    }

    getUsers().then(() => {
            SetLoaded(1);
        }
    );

    function editarUser(item)
    {
        SetUsuario({
            id: item.id ? item.id : '',
            image: item.image ? item.image : '',
            nombre: item.nombre ? item.nombre : '',
            cedula: item.cedula ? item.cedula : '',
            telefono: item.telefono ? item.telefono : '',
            email: item.email ? item.email : '',
            password: item.password ? item.password : '',
        })
        SetEditar(1)
        SetLoaded(0)
    }

    function resetForm()
    {
        SetEditar(0)
        SetUsuario({})
        SetLoaded(0)
    }

    async function actualizarRegistro(usuario)
    {
        await updateDoc(doc(Firebase, "users", usuario.id) , usuario
        ).then(() => resetForm());
    }
    
    async function eliminarRegistro(usuario)
    {
        await deleteDoc(doc(Firebase, "users", usuario.id)
        ).then(() => resetForm());
    }

    return (
        <View style={{flex: 1 , backgroundColor: 'rgb(34,127,163)'}}>
            <ScrollView style={styles.container}>
                {editar ? <>
                    <Text style={styles.titulo}>Editar Usuario "{usuario.nombre}"</Text>

                    <View style={{backgroundColor: '#ffffff'}}>
                        <View style={{width: '80%' , maxWidth: 300 , marginLeft: 'auto' , marginRight: 'auto'}}>
                            <TouchableOpacity style={styles.buttonPequeno} onPress={() => {
                                resetForm()
                            }}>
                                <Text style={styles.buttonPequenoTexto} ><Ionicons name="arrow-back" size={20} color="#0400b6"></Ionicons> Volver al listado</Text>
                            </TouchableOpacity>
                        </View>

                        <Image source={{ uri: usuario.image }} style={{ width: '60%', height: 200 , borderRadius: 30, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />
                        
                        <Text style={{ width: '80%', textAlign: 'center' , marginBottom: 10, marginTop: 10, marginLeft: 'auto', marginRight: 'auto'}}>Información de usuario</Text>
                        <Text style={{ width: '80%', textAlign: 'left' , marginBottom: 10, marginTop: 10, marginLeft: 'auto', marginRight: 'auto'}}>Nombre y Apellido</Text>
                        <TextInput 
                            style={styles.input}
                            placeholder="Nombre"
                            onChangeText={val => {
                                    SetUsuario({
                                    ...usuario,
                                    nombre: val
                                })}
                            }
                            value={usuario.nombre}
                        />
                        
                        <Text style={{ width: '80%', textAlign: 'left' , marginBottom: 10, marginTop: 10, marginLeft: 'auto', marginRight: 'auto'}}>Cédula de identidad</Text>
                        <TextInput 
                            style={styles.input}
                            placeholder="Cedula"
                            onChangeText={val => {
                                    SetUsuario({
                                    ...usuario,
                                    cedula: val
                                })}
                            }
                            value={usuario.cedula}
                        />
                        
                        <Text style={{ width: '80%', textAlign: 'left' , marginBottom: 10, marginTop: 10, marginLeft: 'auto', marginRight: 'auto'}}>Teléfono</Text>
                        <TextInput 
                            style={styles.input}
                            placeholder="Telefono"
                            onChangeText={val => {
                                    SetUsuario({
                                    ...usuario,
                                    telefono: val
                                })}
                            }
                            value={usuario.telefono}
                        />

                        <Text style={{ width: '80%', textAlign: 'center' , marginBottom: 10, marginTop: 10, marginLeft: 'auto' , marginRight: 'auto'}}>Información de inicio de sesión</Text>
                        <Text style={{ width: '80%', textAlign: 'left' , marginBottom: 10, marginTop: 10, marginLeft: 'auto', marginRight: 'auto'}}>Email</Text>
                        <TextInput 
                            style={styles.input}
                            placeholder="Email"
                            onChangeText={val => {
                                    SetUsuario({
                                    ...usuario,
                                    email: val
                                })}
                            }
                            value={usuario.email}
                        />
                        <Text style={{ width: '80%', textAlign: 'left' , marginBottom: 10, marginTop: 10, marginLeft: 'auto', marginRight: 'auto'}}>Contraseña</Text>
                        <TextInput 
                            style={styles.input}
                            placeholder="Contraseña"
                            onChangeText={val => {
                                    SetUsuario({
                                    ...usuario,
                                    password: val
                                })}
                            }
                            value={usuario.password}
                        />
                        
                        

                        {/* <Text>Usuario: "{usuario.nombre}"</Text>
                        <Text>Cédula: "{usuario.cedula}"</Text>
                        <Text>Email: "{usuario.email}"</Text>
                        <Text>Teléfono: "{usuario.telefono}"</Text> */}

                        <View style={{ flexDirection: 'row' }}>
                            <TouchableOpacity style={styles.botones} onPress={() => actualizarRegistro(usuario)}>
                                <Text style={styles.botonListaTexto} ><Ionicons name="create" size={15} color="#0400b6"></Ionicons> Actualizar</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.botonesEliminar} onPress={() => eliminarRegistro(usuario)}>
                                <Text style={styles.botonListaTextoEliminar} ><Ionicons name="trash" size={15} color="#FF0000"></Ionicons> Eliminar</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </> : <>
                    
                    <View style={{flexDirection: 'row' , alignItems: 'center', justifyContent: 'space-between' , width: '80%' , marginRight: 'auto' , marginLeft : 'auto'}}>
                        <Text style={styles.titulo}>Usuarios Registrados</Text>
                        <TouchableOpacity onPress={toggleMenu}>
                            <Ionicons style={styles.heartIcon} name="menu" size={21} color="#ffffff"/>
                        </TouchableOpacity>
                    </View>

                    {loaded ? <>
                        <FlatList
                            data={DATA}
                            renderItem={({item}) => 
                                <View>
                                    <TouchableOpacity style={styles.botonLista} onPress={() => editarUser(item)} >
                                        <Text style={styles.botonListaTexto}>{item.nombre}</Text>
                                        <Text style={styles.botonListaTexto}>{item.estatus}</Text>
                                    </TouchableOpacity>
                                    
                                </View>
                            }
                            keyExtractor={item => item.id}
                            contentContainerStyle={styles.gridLista}
                            
                            />
                    </> : <>
                        <Text>Cargando Usuarios...</Text>
                    </>}
                
                </>}

                

            </ScrollView>

            <BotonesAdmin navigation={navigation} toggleMenu={toggleMenu} menuPos={menuPos}/>
        </View>
    );
}


