import React from "react";
import { StyleSheet ,View ,FlatList , Image , TextInput , ScrollView , TouchableOpacity , Text  } from "react-native";
// import BotonesTienda from "../components/BotonesTienda";
import Ionicons from '@expo/vector-icons/Ionicons';
import { collection, getDocs , query , updateDoc , doc , where , deleteDoc } from "firebase/firestore";
import BotonesAdmin from "../components/BotonesAdmin";
import Firebase from "../db/Firebase";

const styles = StyleSheet.create({
    container:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    containerBox:{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    itemLista: {
        padding: 4
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#0400b6',
        borderWidth: 1,
        color: '#0400b6',
        borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutlineTexto: {
        color: '#0400b6',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        backgroundColor: '#ececec',
        borderWidth: 0,
        // borderRadius: 30,
        marginBottom: 10,
        width: '80%',
        // maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
        color: '#ffffff',
      },
    logo: {
        width: 100,
        height: 187,
        marginBottom: 80,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      botonLista: {
        flexDirection: 'row' , 
        justifyContent: 'space-between',
        width: '100%',
        padding: 9,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#0400b6',
        marginBottom: 8,
        
      },
      botones: {
        flexDirection: 'row' , 
        justifyContent: 'space-between',
        marginHorizontal: 'auto',
        marginVertical: 25,
        padding: 9,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#0400b6',
        marginBottom: 8,
        
      },
      botonListaTexto: {
        color: '#0400b6',
      },    
      botonListaTextoActivo: {
        color: '#008f39',
      },    
      botonListaTextoInactivo: {
        color: '#ff0000',
      },
});


const DATA = [];


async function getTiendas(){
    
    const querySnapshot = await getDocs( query(collection(Firebase, "users") , where('tipo' , '==' , 2)) );
    
    DATA.length = 0;

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      DATA.push({
        id: doc.id, ...doc.data(),
      });
   
    });
} 



export default function TiendasAdmin({navigation}){
    const [editar , SetEditar] = React.useState(0);
    const [usuario , SetUsuario] = React.useState({});
    const [loaded , SetLoaded] = React.useState(0);

    const [menuPos, setMenuPos] = React.useState('translateX(-100%)');
    // console.log(menuPos);
    function toggleMenu()
    {
        if(menuPos == 'translateX(-100%)')
        {
        setMenuPos('translateX(0%)');
        }else{
        setMenuPos('translateX(-100%)');
        }
    }

    function editarTienda(item)
    {
       
        SetUsuario({
            id: item.id ? item.id : '',
            image: item.image ? item.image : '',
            nombre: item.nombre ? item.nombre : '',
            cedula: item.cedula ? item.cedula : '',
            telefono: item.telefono ? item.telefono : '',
            email: item.email ? item.email : '',
            password: item.password ? item.password : '',
            anverso: item.anverso ? item.anverso : '',
            reverso: item.reverso ? item.anverso : '',
            estatus: item.estatus ? item.estatus : '',
        })
        SetEditar(1)
        SetLoaded(0)
    }

    function resetForm()
    {
        SetEditar(0)
        SetUsuario({})
        SetLoaded(0)
    }

    async function cambiarEstatus(usuario)
    {
        let estatus = usuario.estatus == 1 ? 0 : 1;
        
        await updateDoc(doc(Firebase, "users", usuario.id),
            {estatus: estatus},
        ).then(() => resetForm());
    }

    async function actualizarRegistro(usuario)
    {
        await updateDoc(doc(Firebase, "users", usuario.id) , usuario
        ).then(() => resetForm());
    }
    
    async function eliminarRegistro(usuario)
    {
        await deleteDoc(doc(Firebase, "users", usuario.id)
        ).then(() => resetForm());
    }

    getTiendas().then(() => {
            SetLoaded(1);
        }
    );
    return (
        <View style={{flex: 1 , backgroundColor: 'rgb(34,127,163)'}}>
            <ScrollView style={styles.container}>
                <View >
                    {editar ? <>
                        <Text style={styles.titulo}>Editar Usuario "{usuario.nombre}"</Text>
                        

                        <View style={{ backgroundColor: '#ffffff' }}>
                            <View style={styles.containerBox}>
                                <TouchableOpacity style={styles.buttonPequeno} onPress={() => {
                                    resetForm()
                                }}>
                                    <Text style={styles.buttonPequenoTexto} ><Ionicons name="arrow-back" size={20} color="#0400b6"></Ionicons> Volver al listado</Text>
                                </TouchableOpacity>

                                <Text style={{marginTop: 15, marginBottom: 10 , textAlign: 'center' , fontWeight: 'bold'}}>Datos de usuario:</Text>

                                <Image source={{ uri: usuario.image }} style={{ width: '100%', height: 200 , borderRadius: 30, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />
                                
                                <Text style={{ width: '80%', maxWidth: 300 , textAlign: 'center' , marginBottom: 10, marginTop: 10}}>Información de usuario</Text>
                                <TextInput 
                                    style={styles.input}
                                    placeholder="Nombre"
                                    onChangeText={val => {
                                            SetUsuario({
                                            ...usuario,
                                            nombre: val
                                        })}
                                    }
                                    value={usuario.nombre}
                                />
                                
                                <TextInput 
                                    style={styles.input}
                                    placeholder="Cedula"
                                    onChangeText={val => {
                                            SetUsuario({
                                            ...usuario,
                                            cedula: val
                                        })}
                                    }
                                    value={usuario.cedula}
                                />

                                <TextInput 
                                    style={styles.input}
                                    placeholder="Telefono"
                                    onChangeText={val => {
                                            SetUsuario({
                                            ...usuario,
                                            telefono: val
                                        })}
                                    }
                                    value={usuario.telefono}
                                />

                                <Text style={{ width: '80%', maxWidth: 300 , textAlign: 'center' , marginBottom: 10, marginTop: 10}}>Información de inicio de sesión</Text>
                                
                                <TextInput 
                                    style={styles.input}
                                    placeholder="Email"
                                    onChangeText={val => {
                                            SetUsuario({
                                            ...usuario,
                                            email: val
                                        })}
                                    }
                                    value={usuario.email}
                                />
                                
                                <TextInput 
                                    style={styles.input}
                                    placeholder="Contraseña"
                                    onChangeText={val => {
                                            SetUsuario({
                                            ...usuario,
                                            password: val
                                        })}
                                    }
                                    value={usuario.password}
                                />
                                
                                {/* <Text>Usuario: "{usuario.nombre}"</Text>
                                <Text>Email: "{usuario.email}"</Text>
                                <Text>Cédula: "{usuario.cedula}"</Text>
                                <Text>Teléfono: "{usuario.telefono}"</Text> */}
                                
                                <Text style={{marginTop: 15, marginBottom: 10 , textAlign: 'center' , fontWeight: 'bold'}}>Datos de validación:</Text>

                                <Image source={{ uri: usuario.anverso }} style={{ width: '100%' , height: 200 , borderRadius: 30, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />
                                <Image source={{ uri: usuario.reverso }} style={{ width: '100%' , height: 200 , borderRadius: 30, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />


                                
                                <View style={{ flexDirection: 'row' }}>
                                    <TouchableOpacity style={styles.botones} onPress={() => actualizarRegistro(usuario)}>
                                        <Text style={styles.botonListaTexto} ><Ionicons name="create" size={15} color="#0400b6"></Ionicons> Actualizar</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.botones} onPress={() => eliminarRegistro(usuario)}>
                                        <Text style={styles.botonListaTexto}><Ionicons name="trash" size={15} color="#0400b6"></Ionicons> Eliminar</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.botones} onPress={() => cambiarEstatus(usuario)}>
                                        <Text style={styles.botonListaTexto}><Ionicons name="cog" size={15} color="#0400b6"></Ionicons>{ usuario.estatus ? 'Desactivar' : 'Activar'}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>

                    </> : <>
                        <View style={{flexDirection: 'row' , alignItems: 'center', justifyContent: 'space-between' , width: '80%' , marginRight: 'auto' , marginLeft : 'auto'}}>
                            <Text style={styles.titulo}>Tiendas Registradas</Text>
                            <TouchableOpacity onPress={toggleMenu}>
                                <Ionicons style={styles.heartIcon} name="menu" size={21} color="#ffffff"/>
                            </TouchableOpacity>
                        </View>

                        {loaded ? <>
                            <View style={styles.containerBox}>
                                <FlatList
                                    data={DATA}
                                    renderItem={({item}) => 
                                        <View>
                                            <TouchableOpacity style={styles.botonLista} onPress={() => editarTienda(item)} >
                                                <Text style={styles.botonListaTexto}>{item.nombre}</Text>
                                                <Text style={item.estatus ? styles.botonListaTextoActivo : styles.botonListaTextoInactivo}>{item.estatus ? 'activo' : 'inactivo'}</Text>
                                            </TouchableOpacity>
                                            
                                        </View>
                                    }
                                    keyExtractor={item => item.id}
                                    contentContainerStyle={styles.gridLista}
                                    
                                    />

                            </View>
                        </> : <>
                            <Text>Cargando Tiendas...</Text>
                        </>}
                    </>}
                 </View>

            </ScrollView>

            <BotonesAdmin navigation={navigation} toggleMenu={toggleMenu} menuPos={menuPos}/>
        </View>
    );
}


