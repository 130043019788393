// src/services/paymentService.js
import axios from 'axios';

const API_URL = 'https://secure.paguelofacil.com/rest/processTx/AUTH_CAPTURE'; // URL de la API de Paguelo Fácil
// const API_URL = 'https://sandbox.paguelofacil.com/rest/processTx/AUTH_CAPTURE"'; // URL de la API de Paguelo Fácil

export const processPayment = async (paymentData) => {
  try {
    console.log('Sending payment data:', paymentData);
    const response = await axios.post(API_URL, paymentData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.PAGUELO_FACIL_KEY}`, // Usa tu API Key de Paguelo Fácil
      },
    });
    console.log('Received response:', response);
    return response.data;
  } catch (error) {
    console.error('Error response:', error.response);
    throw new Error('Error processing payment: ' + error.response?.data?.message || error.message);
  }
};
