import React from "react";
import { ImageBackground , StyleSheet ,View , Image , TextInput , ScrollView , TouchableOpacity , Text , FlatList} from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import Botones from "../components/Botones";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Firebase from "../db/Firebase"
import { doc, getDoc , collection ,  addDoc , Timestamp } from "firebase/firestore";

const styles = StyleSheet.create({
    container:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    imageFondo: {
        flex: 1,
      },
      containerSafeArea: {
        flex: 1,
        marginTop: 0,
        // minHeight: '100vh',
      },
    nombreListaTexto : {
        width: '60%',
        paddingHorizontal: 8,
    },
    numeroListaTexto : {
        width: '10%',
        textAlign:'right',
    },
    numeroListaTextoFoot : {
        width: '10%',
        textAlign:'right',
        fontWeight: 'bold',
        paddingVertical: 8,
    },
    nombreListaTextoTitulo : {
        width: '60%',
        paddingHorizontal: 8,
        fontSize: 8,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    numeroListaTextoTitulo : {
        width: '10%',
        fontSize: 8,
        fontWeight: 'bold',
        textAlign: 'right',
    },
    itemCarrito: {
        flexDirection:'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomColor: '#adadad',
        borderBottomWidth: 1,
        paddingVertical: 5, 
    },
    imagenCarrito: {
        height: 30,
        width: '100%',
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        width: '45%',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#0400b6',
        borderWidth: 1,
        color: '#0400b6',
        borderRadius: 30,
        marginTop: 10,
        width: '45%',
    },
    buttonGrandeOutlineTexto: {
        color: '#0400b6',
        textAlign: 'center',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
        textAlign: 'center',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        // maxWidth: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    botonLogin: {
        flexDirection :'row', 
        justifyContent: 'center',
        alignItems:'center' ,
        backgroundColor: '#0400b6',
        width: '100%',
        padding: 5,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 15,
      },
      botonCompraText: {
        color: "#fff",
      },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
      },
    logo: {
        width: 100,
        height: 187,
        marginBottom: 80,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      heartIcon: {
        padding: 10,
        backgroundColor: '#0400b6',
        borderRadius: 5,
        marginLeft: 5,
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    cardBlanca: {
        backgroundColor: '#ffffff',
        paddingHorizontal: 15,
        paddingVertical: 8,
        borderRadius: 15,
    },
});

const carritoData = [];
let authToken = null;

const authFn = async () => {
authToken = await AsyncStorage.getItem('authToken');
}


async function getCarrito()
{
    const carrito = await AsyncStorage.getItem('carritoActual');
    //console.log(carrito)
    carritoData.length = 0;
    let carritoArray = carrito ? JSON.parse(carrito) : null;
    //console.log(carritoArray)
    if(carritoArray && carritoArray.length)
    {
        carritoArray.forEach(prod => {
            carritoData.push(prod);
        });
    }
}

function carritoTotal()
{
    let total = 0;

    carritoData.forEach(item => {
        total += item.precio * item.cantidad;
    })

    return total;
}

async function eliminarCarrito()
{
    const carrito = await AsyncStorage.setItem('carritoActual' , '');

    carritoData.length = 0;
}

export default function Carrito({navigation}){

    const [loaded , SetLoaded] = React.useState(0);
    const [comprarForm , SetComprarForm] = React.useState(0);
    const [nombre , SetNombre] = React.useState('');
    const [email , SetEmail] = React.useState('');
    const [telefono , SetTelefono] = React.useState('');
    const [detalles , SetDetalles] = React.useState('');

    getCarrito().then(() => {
        SetLoaded(1)
    });

    function registrarCompra()
    {
      try{
        var newOrden = collection(Firebase, "ordenes");
        let tiendaId = carritoData[0].tiendaId;
        let totalCarrito = carritoTotal();
        // //console.log(totalCarrito);return;

        addDoc(newOrden, {
          email: email,
          nombre: nombre,
          telefono: telefono,
          detalles: detalles,
          userId: authToken,
          tiendaId: tiendaId,
          total: totalCarrito,
          productos: carritoData,
          estatus: 1,
          fechaCreado: Timestamp.fromDate(new Date()),
        }).then((docRef) => {
          SetComprarForm(0);
          SetLoaded(0);
          eliminarCarrito();
          alert('Orden realizada con exito');
        });
      }catch(error){
        //console.log(error);
      }finally{
        
      }
      
    }

    return (
        <View style={{flex: 1}}>
            <ImageBackground source={require('../img/fondo.jpg')} resizeMode="cover" style={styles.imageFondo}>
                <ScrollView style={styles.containerSafeArea}>

                    <View style={{ width: '80%', marginHorizontal: 'auto', }}>
                        <Text style={styles.titulo}>Carrito</Text>

                        {loaded ? <>
                            {carritoData.length ? <>

                                <View style={styles.cardBlanca}>
                                    <View style={styles.itemCarrito}>
                                        <View style={styles.numeroListaTextoTitulo}>
                                            
                                        </View>
                                        <Text style={styles.nombreListaTextoTitulo}>Producto</Text>
                                        <Text style={styles.numeroListaTextoTitulo}>Cantidad</Text>
                                        <Text style={styles.numeroListaTextoTitulo}>Precio</Text>
                                        <Text style={styles.numeroListaTextoTitulo}>Total</Text>
                                    </View>

                                    <FlatList
                                        data={carritoData}
                                        renderItem={({item}) => 
                                            <View style={styles.itemCarrito}>
                                                <View style={styles.numeroListaTexto}>
                                                    <Image
                                                    style={styles.imagenCarrito}
                                                    source={{ uri : item.image}}
                                                    />
                                                </View>
                                                <Text style={styles.nombreListaTexto}>{item.nombre}</Text>
                                                <Text style={styles.numeroListaTexto}>{item.cantidad}</Text>
                                                <Text style={styles.numeroListaTexto}>${item.precio}</Text>
                                                <Text style={styles.numeroListaTexto}>${item.precio * item.cantidad}</Text>
                                            </View>
                                        }
                                        keyExtractor={item => item.productoId}
                                        contentContainerStyle={styles.gridLista}
                                        
                                        />

                                    <View style={styles.itemCarrito}>
                                        <View>
                                            
                                        </View>
                                        <Text></Text>
                                        <Text></Text>
                                        <Text style={styles.numeroListaTextoFoot}>Total</Text>
                                        <Text style={styles.numeroListaTextoFoot}>${carritoTotal()}</Text>
                                    </View>
                                </View>

                                <View style={{flexDirection: 'row' , justifyContent: 'space-between' , marginVertical: 20}}>
                                    <TouchableOpacity  style={styles.buttonGrandeOutline } onPress={() => eliminarCarrito().then(() => { SetLoaded(0) })}>
                                        <Text style={styles.buttonGrandeOutlineTexto}><Ionicons style={{marginRight: 10}} name="trash" size={15} color="#0400b6"></Ionicons> Eliminar Carrito</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity style={styles.buttonGrande} onPress={() => SetComprarForm(1)}>
                                        <Text style={styles.buttonGrandeTexto}><Ionicons style={{marginRight: 10}} name="cash" size={15} color="#ffffff"></Ionicons> Comprar</Text>
                                    </TouchableOpacity>
                                </View>

                                {comprarForm ? <>
                                    <TouchableOpacity style={styles.botonLogin} onPress={() => SetComprarForm(0)}>
                                    <Ionicons style={styles.heartIcon} name="close-circle" size={20} color="#ffffff"/>
                                    <Text style={styles.botonCompraText}>Cancelar</Text>
                                    </TouchableOpacity>
                                    
                                    <TextInput 
                                        style={styles.input}
                                        placeholder="Nombre"
                                        onChangeText={SetNombre}
                                        value={nombre}
                                    />
                                    <TextInput 
                                        style={styles.input}
                                        placeholder="Email"
                                        onChangeText={SetEmail}
                                        value={email}
                                    />
                                    <TextInput 
                                        style={styles.input}
                                        placeholder="Telêfono"
                                        onChangeText={SetTelefono}
                                        value={telefono}
                                    />
                                    <TextInput 
                                        style={styles.input}
                                        multiline
                                        numberOfLines={4}
                                        placeholder="Detalles"
                                        onChangeText={SetDetalles}
                                        value={detalles}
                                    />

                                    <TouchableOpacity style={styles.botonLogin} onPress={() => registrarCompra()}>
                                    <Ionicons style={styles.heartIcon} name="checkbox-outline" size={20} color="#ffffff"/>
                                    <Text style={styles.botonCompraText}>Confirmar Compra</Text>
                                    </TouchableOpacity>
                                </> : <></>}
                            </> : <>
                                <Ionicons style={{color:'#0400b6' , textAlign: 'center' , marginTop: 100}} name="cart" size={100} color="#0400b6"></Ionicons>
                                <Text style={styles.titulo}>No tienes elementos en el carrito</Text>
                            </>}
                        </> : <>
                            <Text>Cargando Datos...</Text>
                        </>}
                        
                    </View>

                </ScrollView>

                

                <Botones navigation={navigation}/>
            </ImageBackground>
        </View>
    );
}