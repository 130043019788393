import React from "react";
import { ImageBackground , StyleSheet ,View , Image , TextInput , ScrollView , TouchableOpacity , Text , Alert } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import BotonesTienda from "../components/BotonesTienda";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Firebase from "../db/Firebase";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import * as ImagePicker from 'expo-image-picker';
import { doc, setDoc , updateDoc, getDocs , addDoc , collection , getCountFromServer , Timestamp , query , where, Firestore} from "firebase/firestore";

const styles = StyleSheet.create({
    container:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 50,
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#0400b6',
        borderWidth: 1,
        color: '#0400b6',
        borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutlineTexto: {
        color: '#0400b6',
        textAlign: 'center',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        backgroundColor: '#ececec',
        borderWidth: 0,
        // borderRadius: 30,
        marginBottom: 10,
        width: '100%',
        // maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    containerDetalles: {
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginTop: 15,
        marginBottom: 5,
        width: '100%',
        overflow: 'hidden',
        padding: 10,
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
        color: '#ffffff',
      },
    logo: {
        width: 187,
        height: 187,
        marginBottom: 20,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      detalleHome: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        // borderBottomColor: '#0400b6',
        // borderBottomWidth: 1,
        padding: 10,
        marginBottom: 5,
      },
      descripcionItem: {
        fontSize: 16,
      },
      detalleItem:{
        fontSize: 21,
      },
});


let userId = null;
let tipoUser = null;

  async function authFn() {
    userId = await AsyncStorage.getItem('authToken');
    tipoUser = await AsyncStorage.getItem('tipoUser');
  }

  authFn()

async function getProductos()
{
    const coll = collection(Firebase, "productos");
    const q = query(coll, where("userId", "==", userId));
    const snapshot = await getCountFromServer(q);
    const cantidadProductos = snapshot.data().count;

    return cantidadProductos;
}
async function getDataTienda()
{
    const colTienda = collection(Firebase, "tiendas");
    const qTienda = query(colTienda, where("userId", "==", userId));
    const querySnapshot = await getDocs(qTienda);
    const tiendaSnap = querySnapshot.docs[0];

    return tiendaSnap;
    // const tiendaData = tiendaSnap.data();
    // const tiendaId = tiendaSnap.id;
   
}

async function pickImage()
{
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
  
  
      if (!result.canceled) {
      
        return result.assets[0].uri
      }

      return null

}

async function guardarDatos(data , tiendaId , image = null ,navigation)
{
    let nombreImagen;
    let storage;
    let storageRef;

    if(image && image.search('https://') == -1)
    {
        nombreImagen = 'tiendas/perfil-'+userId+'.jpg';
        storage = getStorage();
        storageRef = ref(storage, nombreImagen);
        
        await uploadString(storageRef, image, 'data_url').then(async (snapshot) => {
            await getDownloadURL(storageRef).then((url) => {
                data.image = url;
                
            })
           
        });

        // data.image = nombreImagen;
    }
    
   
    await updateDoc(doc(Firebase, "tiendas", tiendaId),
        data,
      );

      navigation.navigate('Notificacion' , {
        type: 'success',
        title: '¡Datos guardados!',
        message: 'Sus datos se actualizaron correctamente.'
      })
}

export default function HomeTienda({navigation}){
    
    const [tiendaId, SetTiendaId] = React.useState('');
    const [nombre, SetNombre] = React.useState('');
    const [descripcion, SetDescripcion] = React.useState('');
    const [direccion, SetDireccion] = React.useState('');
    const [telefono, SetTelefono] = React.useState('');
    const [email, SetEmail] = React.useState('');
    const [whatsapp, SetWhatsapp] = React.useState('');
    const [cantidadProductos, SetCantidadProductos] = React.useState(0);
    const [loaded, SetLoaded] = React.useState(0);
    const [image, SetImage] = React.useState(null);

    const [menuPos, setMenuPos] = React.useState('translateX(-100%)');
    // console.log(menuPos);
    function toggleMenu()
    {
        if(menuPos == 'translateX(-100%)')
        {
        setMenuPos('translateX(0%)');
        }else{
        setMenuPos('translateX(-100%)');
        }
    }

    

    if(!loaded){
        authFn().then(() => {
            
                getDataTienda().then((dataTienda) => {
                    let datos = dataTienda.data()
                    
                    SetNombre(datos.name ? datos.name : '')
                    SetDescripcion(datos.about ? datos.about : '')
                    SetDireccion(datos.direccion ? datos.direccion : '')
                    SetTelefono(datos.telefono ? datos.telefono : '')
                    SetEmail(datos.email ? datos.email : '')
                    SetWhatsapp(datos.whatsapp ? datos.whatsapp : '')
                    SetTiendaId(dataTienda.id)
                    SetImage(datos.image)
                    
                    // if(datos.image && image == null)
                    // {
                    //     getDownloadURL(ref( getStorage() , datos.image)).then((url) => {
                    //         SetImage(url)
                    //     })
                    // }
                })

                getProductos().then((cantidad) => {
                    SetCantidadProductos(cantidad)
                })

                SetLoaded(1)
            
        })
    }
    

    return (
        <View style={{flex: 1 , backgroundColor: 'rgb(34,127,163)'}}>
            <ImageBackground source={require('../img/mates.svg')} resizeMode="cover" style={styles.imageFondo}>
                {loaded && <>
                    <ScrollView style={styles.container}>
                        <View style={{flexDirection: 'row' , alignItems: 'center', justifyContent: 'space-between' , width: '80%' , marginRight: 'auto' , marginLeft : 'auto'}}>
                                <Text style={styles.titulo}>Inicio</Text>
                                <TouchableOpacity onPress={toggleMenu}>
                                    <Ionicons style={styles.heartIcon} name="menu" size={21} color="#ffffff"/>
                                </TouchableOpacity>
                        </View>
                        
                        {/* <Image
                        style={styles.logo}
                        source={ require('../img/logo.svg')}
                        /> */}

                            <View style={{backgroundColor: '#ececec', padding: 15}}>
                                <Text style={{color: 'rgb(34,127,163)' , textAlign:'center' , fontSize: 25}}>
                                    Datos de la tienda
                                </Text>
                            </View>
                        
                            <View style={{backgroundColor: '#ffffff' , padding: 15}}>
                                <View style={{width: '80%', marginLeft: 'auto' , marginRight: 'auto'}}>

                                    {image && <Image source={{ uri: image }} style={{ width: '60%', height: 200 , borderRadius: 30, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />}
                                    <TouchableOpacity style={{marginBottom: 20}} >
                                        <Text style={{textAlign: 'center'}} onPress={() => pickImage().then((image) => {SetImage(image)})}>
                                            Seleccionar Imagen
                                        </Text>
                                    </TouchableOpacity>

                                    <Text>Nombre de la tienda</Text>
                                    <TextInput 
                                        style={styles.input}
                                        placeholder="Nombre"
                                        onChangeText={SetNombre}
                                        value={nombre}
                                    />
                                    
                                    
                                    <Text>Descripción de la tienda</Text>
                                    <TextInput 
                                        style={styles.input}
                                        multiline
                                        numberOfLines={4}
                                        placeholder="Descripcion"
                                        onChangeText={SetDescripcion}
                                        value={descripcion}
                                    />
                                    <Text>Dirección de la tienda</Text>
                                    <TextInput 
                                        style={styles.input}
                                        multiline
                                        numberOfLines={4}
                                        placeholder="Dirección"
                                        onChangeText={SetDireccion}
                                        value={direccion}
                                    />

                                    <Text>Teléfono</Text>
                                    <TextInput 
                                        style={styles.input}
                                        placeholder="Teléfono"
                                        onChangeText={SetTelefono}
                                        value={telefono}
                                    />
                                    
                                    <Text>Email de contacto</Text>
                                    <TextInput 
                                        style={styles.input}
                                        placeholder="Email de contacto"
                                        onChangeText={SetEmail}
                                        value={email}
                                    />
                                    
                                    <Text>Whatsapp</Text>
                                    <TextInput 
                                        style={styles.input}
                                        placeholder="Whatsapp"
                                        onChangeText={SetWhatsapp}
                                        value={whatsapp}
                                    />

                                    <TouchableOpacity style={styles.input} onPress={() => guardarDatos({
                                            name : nombre ,
                                            about: descripcion,
                                            direccion: direccion,
                                            telefono: telefono,
                                            email: email,
                                            whatsapp: whatsapp
                                        } , tiendaId , image , navigation )}>
                                        <Text style={styles.buttonGrandeOutlineTexto} >
                                            Guardar
                                        </Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity style={styles.input} onPress={() => navigation.navigate('PaymentForm')}>
                                        <Text style={styles.buttonGrandeOutlineTexto} >
                                            Formulario de pago 
                                        </Text>
                                    </TouchableOpacity>
                                    
                                    <View style={styles.containerDetalles}>
                                        <View style={styles.detalleHome}>
                                            <Text style={styles.descripcionItem}>Productos Registrados:</Text>
                                            <Text style={styles.detalleItem}>{cantidadProductos}</Text>
                                        </View>

                                        <View style={styles.detalleHome}>
                                            <Text style={styles.descripcionItem}>Ventas Registradas:</Text>
                                            <Text style={styles.detalleItem}>0</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                    </ScrollView>
                </>}
                <BotonesTienda navigation={navigation} toggleMenu={toggleMenu} menuPos={menuPos}/>
            </ImageBackground>
        </View>
    );
}