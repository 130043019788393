// src/components/PaymentForm.js
import React, { useState } from 'react';
import { View , TextInput , ScrollView , TouchableOpacity , Text , StyleSheet , Image} from "react-native";
import {Picker} from '@react-native-picker/picker';
import { processPayment } from '../services/paymentService';
import axios from 'axios';

const IPIFY = 'https://api.ipify.org?format=json';
const precioPlan = 15.00;

const styles = StyleSheet.create({
  background: {
    padding: 20,
    width: '100%',
    backgroundColor: '#ffffff',
    // marginTop: 100,
    // marginBottom: 100,
  },
  container: {
    padding: 20,
    maxWidth: 300,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginTop: 100,
    // marginBottom: 100,
  },
  formGroup: {
    marginBottom: 15,
  },
  label: {
    marginBottom: 5,
    fontSize: 16,
  },
  input: {
    padding: 14,
    borderStyle: 'solid',
    borderColor: '#0400b6',
    borderWidth: 1,
    borderRadius: 30,
    marginBottom: 5,
    width: '100%',
},
  button: {
    padding: 14,
    textAlign: 'center',
    backgroundColor: '#0400b6',
    color: '#ffffff',
    borderRadius: 30,
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
  },
  buttonText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 18,
  },
  errorText: {
    color: 'red',
    marginTop: 10,
  },
  mensajeText: {
    color: 'green',
    marginTop: 10,
  },
  logo: {
    width: 100,
    height: 187,
    marginBottom: 20,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  titulo: {
    fontWeight: 'bold',
    fontSize: 23.5,
    margin: 20,
    textAlign: 'center',
  },
});




const getIPAddress = async () => {
    try {
      const response = await axios.get(IPIFY);
      return response.data.ip;
    } catch (error) {
      console.error('Error fetching IP address:', error);
      throw error;
    }
  };

const PaymentForm = () => {
  const [amount, setAmount] = useState(precioPlan);
  const [cardNumber, setCardNumber] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [name, setName] = useState('');
  const [apellido, setApellido] = useState('');
  const [cardType, setCardType] = useState('visa');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mensaje, setMensaje] = useState(null);
  const [ipPublic, setIpPublic] = useState(null);

  if(ipPublic == null)
    {
      getIPAddress().then((res) => {
        setIpPublic(res);
        // console.log(res);
      })
    }

  async function enviarForm() {
    // event.preventDefault();
    setLoading(true);
    setError(null);

    const paymentData = {
        
        cclw : "4F32187D6D3A8A64B64F46F59A5265751FB7FD963DB2206A7FA5A4BDEB592AB2F8A0FA45FC0ADE504215AA25E1D6FBE6A956E815C764CDF7A56CB76A97F803C3" ,
        amount : amount,
        taxAmount : 0,
        email : email,
        phone: phone,
        address : address,
        concept : "Pago de suscripción",
        description : "Pago de suscripción",
        ipCheck : ipPublic,
        lang : 'ES', //EN
		    
		customFieldValues : {},
		cardInformation : {
		    cardNumber : cardNumber ,
		    expMonth : expiryMonth ,
		    expYear : expiryYear ,
		    cvv : cvv,
		    firstName : name,
		    lastName : apellido,
		    cardType : cardType,
        }
    };
    

    try {
      const response = await processPayment(paymentData);
      setMensaje('Payment successful: ' + response.message);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.background}>
      <View style={styles.container}>
                  <Text style={styles.titulo}>Formulario de pago</Text>
                  <Image
                    style={styles.logo}
                    source={ require('../img/logo.png')}
                    />

        {/* <View style={styles.formGroup}>
          <Text style={styles.label}>Amount</Text>
          <TextInput
            style={styles.input}
            keyboardType="numeric"
            value={amount}
            onChangeText={setAmount}
            required
          />
        </View> */}
        <View style={styles.formGroup}>
          <Text style={styles.label}>Card Number</Text>
          <TextInput
            style={styles.input}
            value={cardNumber}
            onChangeText={setCardNumber}
            required
          />
        </View>
        <View style={styles.formGroup}>
          <Text style={styles.label}>Card Type</Text>
          {/* <TextInput
            style={styles.input}
            value={cardType}
            onChangeText={setCardType}
            required
          /> */}
          <Picker
            selectedValue={cardType}
            style={styles.input}
            onValueChange={(itemValue, itemIndex) =>
              setCardType(itemValue)
            }>
            <Picker.Item label="Visa" value="visa" />
            <Picker.Item label="Master Card" value="mastercard" />
            <Picker.Item label="American Express" value="americanExpress" />
            <Picker.Item label="Dankort" value="dankort" />
            <Picker.Item label="Discover" value="discover" />
          </Picker>
        </View>
        <View style={styles.formGroup}>
          <Text style={styles.label}>Teléfono</Text>
          <TextInput
            style={styles.input}
            value={phone}
            onChangeText={setPhone}
            required
          />
        </View>
        <View style={styles.formGroup}>
          <Text style={styles.label}>Dirección</Text>
          <TextInput
            style={styles.input}
            value={address}
            onChangeText={setAddress}
            required
          />
        </View>
        <View style={styles.formGroup}>
          <Text style={styles.label}>Mes de vencimiento</Text>
          <TextInput
            style={styles.input}
            value={expiryMonth}
            onChangeText={setExpiryMonth}
            required
          />
        </View>
        <View style={styles.formGroup}>
          <Text style={styles.label}>Año de vencimiento</Text>
          <TextInput
            style={styles.input}
            value={expiryYear}
            onChangeText={setExpiryYear}
            required
          />
        </View>
        <View style={styles.formGroup}>
          <Text style={styles.label}>CVV</Text>
          <TextInput
            style={styles.input}
            value={cvv}
            onChangeText={setCvv}
            required
          />
        </View>
        <View style={styles.formGroup}>
          <Text style={styles.label}>Nombre</Text>
          <TextInput
            style={styles.input}
            value={name}
            onChangeText={setName}
            required
          />
        </View>
        <View style={styles.formGroup}>
          <Text style={styles.label}>Apellido</Text>
          <TextInput
            style={styles.input}
            value={apellido}
            onChangeText={setApellido}
            required
          />
        </View>
        <View style={styles.formGroup}>
          <Text style={styles.label}>Email</Text>
          <TextInput
            style={styles.input}
            keyboardType="email-address"
            value={email}
            onChangeText={setEmail}
            required
          />
        </View>
        <TouchableOpacity style={styles.button} onPress={enviarForm} disabled={loading}>
          <Text style={styles.buttonText}>{loading ? 'Procesando pago...' : 'Enviar $' + amount}</Text>
        </TouchableOpacity>
        {error ? <Text style={styles.errorText}>{error}</Text> : null}
        {mensaje ? <Text style={styles.mensajeText}>{mensaje}</Text> : null}
      </View>
    </View>
  );
};

export default PaymentForm;
