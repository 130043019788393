import React from "react";
import Ionicons from '@expo/vector-icons/Ionicons';
import { SafeAreaView , 
          ImageBackground , 
          StyleSheet , 
          FlatList,
          Text , 
          ScrollView,
          Image,
          View , 
          TouchableOpacity , 
          TextInput} from "react-native";
import Firebase from "../db/Firebase";

import { doc, setDoc , getDocs , addDoc , collection , Timestamp , query , or, where, Firestore , orderBy, startAt, endAt , limit} from "firebase/firestore";
import AsyncStorage from '@react-native-async-storage/async-storage';

// import Botones from "../components/Botones";

const styles = StyleSheet.create({
    container: {
      flex: 1,
      // backgroundColor: '#ececec',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageFondo: {
      flex: 1,
      backgroundPosition : 'center',
    },
    imageFondoCat: {
      flex: 1,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    inputBuscar: {
        // padding: 14,
        // borderRadius: 5,
        // marginBottom: 5,
        // marginTop: 15,
        paddingLeft: 10,
        marginLeft: 'auto',
        width: '100%',
    },
    button: {
        // display: 'inline-block',
        flex : 1,
        // borderStyle: 'solid',
        // borderColor: '#0400b6',
        backgroundColor: '#ffffff',
        borderWidth: 0,
        // borderWidth: 2,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15, 
        // borderRadius: 5,
        // marginLeft: 4,
    },
    buttonFiltro: {
        // display: 'inline-block',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: '#0400b6',
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderRadius: 5,
        // marginLeft: 4,
        width: '80%',
    },
    buttonActivo: {
        // display: 'inline-block',
        flex : 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        backgroundColor: '#ffffff',
        borderWidth: 0,
        borderBottomWidth: 2,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        // borderRadius: 5,
        marginRight: 10,
        // marginLeft: 4,
    },
    buttonCat:{
      display: 'block',
      flex : 1,
      backgroundColor: '#ffffff',
      borderWidth: 0,
      paddingTop: 10,
      paddingRight: 15,
      paddingBottom: 10,
      paddingLeft: 15, 
    },
    buttonCatPrincipal:{
      display: 'block',
      flex : 1,
      backgroundColor: '#ffffff',
      borderWidth: 0,
      paddingTop: 10,
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingRight: 15,
      paddingBottom: 10,
      paddingLeft: 15, 
      textAlign:'center',
    },
    buttonText:{
      color: '#000000',
      fontSize: 17,
      textAlign: 'center',
    },
    buttonTextActivo:{
      color: '#000000',
      fontSize: 17,
      textAlign: 'center',
    },
    containerSafeArea: {
      flex: 1,
      marginTop: 0,
      // minHeight: '100vh',
    },
    gridLista : {
      width: '80%',
      // flexWrap: 'wrap',
      justifyContent: 'space-between',
    //   flexDirection: 'row',
      marginLeft: 'auto',
      marginRight: 'auto',
    //   flexGrow: 1,
    },
    itemLista : {
    //   width: '200px',
      width:'100%',
      justifyContent: 'space-between',
      marginHorizontal: 5,
      marginTop: 15,
      // borderRadius: 20,
      overflow: 'hidden',
      // backgroundColor: '#ffffff',
    },
    imagenLista: {
      width: '50%',
      height: 170,
      borderRadius: 5,
      // marginTop: 5,
      // marginBottom: 5,
    },
    titulo: {
      fontWeight: 'bold',
      fontSize: 23.5,
      margin: 20,
    },
    tituloLista: {
      fontWeight: 'bold',
      fontSize: 25,
      textAlign: 'center',
      width: '100%',
      color: 'rgb(34,127,163)',
    },
    precioLista: {
      fontWeight: 'bold',
      textAlign: 'center',
      width: '100%',
      fontSize: 16,
      marginTop: 5,
      color: 'rgb(34,127,163)',
    },
    descripcionLista: {
      marginBottom: 15,
    },
    botonVer: {
      textAlign: 'right',
    },
    botonVerTexto: {
      color: '#0400b6',
      fontWeight: 'bold',
    },
    buttonContainer: {
      // flex: 1,
      // flexDirection: 'row',
      width: '80%',
      // marginVertical: 10,
      paddingVertical: 8,
    },
    searchTop: {
      flexDirection: 'row',
      width: '80%',
      justifyContent: 'space-between',
      marginTop: 50,
      // backgroundColor: '#ffffff',
      borderRadius: 5,
    },
    searchTopInput: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      width: '80%',
      borderStyle: 'solid',
      borderColor: '#0400b6',
      borderWidth: 1,
      padding: 5,
      borderRadius: 5,
      marginTop: 'auto',
      marginBottom: 'auto',

    },
    searchIcon: {
        // marginTop: 15,
        // width: 20,
        padding: 5,
        borderRightColor: '#0400b6',
        borderRightWidth: 1,
        borderStyle: 'solid',
    },
    heartIcon: {
        padding: 10,
        // backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 5,
        marginLeft: 5,
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    tituloPrincipal: {
      fontSize: 25,
      color: '#ffffff',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    tituloTienda : {
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#ffffff',
      fontSize: 15,
      marginBottom: 15,
    },
    textTienda : {
      color: '#ffffff',
    },
    imgTienda : {
      height: 50,
      width: '100%',
    },
    containerTitulo: {
      padding: 20,
      backgroundColor: '#0400b6',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '80%',
      flexDirection: 'row',
      borderRadius: 20,
      marginTop: 40,
      marginBottom: 10,
    },
    containerDescripcion: {
      // padding: 30,
      width: '80%',
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'justify',
    },
    textCategoria: {
      // paddingHorizontal: 30,
      // paddingVertical: 60,
      fontSize: 13,
      fontWeight: 'bold',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: 10,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 10,
      // marginBottom: 10,
      backgroundColor: 'rgba(255,255,255,0.6)',
    },
    buttonCategoria: {
      // backgroundColor: '#0400b6',
      // borderRadius: 30,
      margin: '1%',
      fontWeight: 'bold',
      overflow: 'hidden',
      // shadowColor: '#0400b6',
      // shadowOffset: {width: 0, height: 5},
      // shadowOpacity: 0.1,
      // shadowRadius: 10,
      width: '49.5%',
      height: 150,
      justifyContent: 'center',
      alignItems: 'center',
    },
    input: {
      padding: 5,
      // borderStyle: 'solid',
      // borderColor: '#0400b6',
      // borderWidth: 1,
      borderRadius: 10,
      margin: 5,
      backgroundColor: '#ffffff',
    },
    filtros: {
      position: 'absolute' , 
      top: 0 , 
      right: 0, 
      width: '50%' , 
      maxWidth: 500, 
      zIndex: 999 , 
      backgroundColor: 'rgb(34,127,163)' , 
      height: '100%',
      shadowColor: '#000000',
      shadowOffset: {width: -20, height: 5},
      shadowOpacity: 0.5,
      shadowRadius: 50,
    },
    filtrosHeader: {
      flexDirection: 'row' , 
      justifyContent: 'space-between' , 
      marginBottom: 15,
      backgroundColor: '#ffffff',
      padding: 20,
      paddingTop: 50,
    },
    filtrosBody:{
      padding: 20,
    },
    filtrosTitulo:{
      textAlign: 'center',
      color: '#000000',
      fontSize: 20.
    },
    filtrosSubTitulo:{
      textAlign: 'center',
      color: '#ffffff',
      fontSize: 11
    },
  });

  function LogoutFn()
  {
      AsyncStorage.setItem('authToken', '');
      AsyncStorage.setItem('tipoUser', '');
      window.location.reload(false);
  }

  let authToken = null;

  async function authFn(){
    authToken = await AsyncStorage.getItem('authToken');
  }

  authFn()

  const DATA = [
    // {
    //   picture: 'https://www.entornoestudiantil.com/wp-content/uploads/2018/03/retail-carrito-compra.jpg',
    //   name: 'Producto1',
    //   about: 'Producto para la venta'
    // },
    // {
    //   picture: 'https://www.entornoestudiantil.com/wp-content/uploads/2018/03/retail-carrito-compra.jpg',
    //   name: 'Producto1',
    //   about: 'You don’t have to use many state variables. State variables can hold objects and arrays just fine, so you can still group related data together. However, unlike this.setState in a class, updating a state variable always replaces it instead of merging it.'
    // },
  ];

  const CATEGORIAS = [];
  const SUBCATEGORIAS = [];

    
  const Item = ({item , navigation}) => (
    <View style={styles.itemLista}>
      <TouchableOpacity onPress={() => navigation.navigate('Producto' , {
        productoId : item.id,
        userId: item.userId,
      })} style={{flexDirection: 'row' , backgroundColor: '#ececec'}}>
        <Image source={{ uri: item.image }} style={styles.imagenLista}/>

        <View style={{ width: '50%', alignItems: 'center' , padding: 10}}>
          <Text style={styles.tituloLista}>{item.nombre}</Text>
          <Text style={styles.precioLista}>${item.precio}</Text>
        </View>
        
      </TouchableOpacity>
      
      
      {/* <Text style={styles.descripcionLista}>{item.descripcion && item.descripcion.substring(0,100)}...</Text> */}

      {/* <TouchableOpacity style = {styles.botonVer}>
        <Text style = {styles.botonVerTexto}>Ver Productos <Ionicons name="arrow-forward" size={20} color="#0400b6"></Ionicons></Text>
      </TouchableOpacity> */}
    </View>
  );

  async function getProductos(buscar = null , userId = null , categoria = null)
  {
      let q = query(collection(Firebase, "productos") , limit(50));

      // if(buscar != null || buscar != '')
      // {
        // const q = query(collection(Firebase, "productos") , orderBy('nombre') , startAt(buscar) , endAt(buscar+'\uf8ff'));
        // //console.log(buscar)     
      // }
      
      if(categoria != null || categoria != '')
      {
        let q = query(
                  collection(Firebase, "productos") , 
                              where('categoria' , '==' , categoria) ,
                              orderBy('fechaCreado' , 'desc') ,
                              limit(100));
        // //console.log(buscar)     
      }

      const querySnapshot = await getDocs(q);
      
      DATA.length = 0;
      //console.log(categoria);
      
      querySnapshot.forEach((doc) => {
          DATA.push({
              id: doc.id, ...doc.data()

          });
          // //console.log(doc.id, " => ", doc.data());
          //console.log(DATA.length)
      });
      //console.log(DATA);

      return DATA;
  }

  async function getProductosCategoria(categoria = null)
  {
      // let q = query(collection(Firebase, "productos"));

      // if(buscar != null || buscar != '')
      // {
        const q = query(collection(Firebase, "productos") , 
                        or(
                          where('categoria' , '==' , categoria) , 
                          where('subcategoria' , '==' , categoria)
                        ),
                        orderBy('fechaCreado' , 'desc') ,
                        limit(100));
        // //console.log(buscar)     
      // }

      const querySnapshot = await getDocs(q);
      
      DATA.length = 0;
      //console.log(categoria);
      
      querySnapshot.forEach((doc) => {
          DATA.push({
              id: doc.id, ...doc.data()

          });
          // //console.log(doc.id, " => ", doc.data());
          // //console.log('DATA.length')
      });

      return DATA;
  }
  
  async function getCategorias(buscar = null)
  {
      const q = query(collection(Firebase, "categorias") , where('padre' , '==' , null) , orderBy('nombre') );

      const querySnapshot = await getDocs(q);
      
      CATEGORIAS.length = 0;
      
      querySnapshot.forEach((doc) => {
          CATEGORIAS.push({
              id: doc.id, ...doc.data()

          });
          // //console.log(doc.id, " => ", doc.data());
          //console.log(CATEGORIAS.length)
      });
       
      return CATEGORIAS;
  }
  
  async function getSubCategorias(idRef)
  {//console.log('idRef' + idRef)
      const q = query(collection(Firebase, "categorias") , where('padre' , '==' , idRef) , orderBy('nombre') );

      const querySnapshot = await getDocs(q);
      
      SUBCATEGORIAS.length = 0;
      
      querySnapshot.forEach((doc) => {
          SUBCATEGORIAS.push({
              id: doc.id, 
              ...doc.data()

          });
          // //console.log(doc.id, " => ", doc.data());
          //console.log(SUBCATEGORIAS.length)
      });

      return SUBCATEGORIAS;
  }
  
// //console.log(use)
function ProductosLista({route, navigation}){
      const [loaded , SetLoaded] = React.useState(0);
      const [buscar , SetBuscar] = React.useState('');
      const [cat , SetCat] = React.useState(null);
      const [catPri , SetCatPri] = React.useState(null);

      // filtros
      const [filtros , SetFiltros] = React.useState(0);
      const [precioMinimo , SetPrecioMinimo] = React.useState('');
      const [precioMaximo , SetPrecioMaximo] = React.useState('');

      function resetCat()
      {
        SetBuscar('')
        SetCat(null)
        SetLoaded(0)
      }

      function filtrarItems(buscar)
      {
        let filtrado 
        
        filtrado = DATA.filter((el) => 
        el.nombre.toLowerCase().includes(buscar.toLowerCase()))
        
        DATA.length = 0;
        DATA.push(...filtrado);

        //console.log(filtrado);
        // SetLoaded(0);
      }
      
      function filtrarItemsAdv(buscar)
      {
        let filtrado 
        let filtros = 0;
        
        if(precioMinimo && precioMinimo != '')
        {
          filtrado = DATA.filter((el) => 
          parseFloat(el.precio) >= parseFloat(precioMinimo))
          DATA.length = 0;
          DATA.push(...filtrado);
        }
        
        if(precioMaximo && precioMaximo != '')
        {
          filtrado = DATA.filter((el) => 
          parseFloat(el.precio) <= parseFloat(precioMaximo))
          DATA.length = 0;
          DATA.push(...filtrado);
        }

        //console.log(filtrado);
        // SetLoaded(0);
      }

      function buscarCat(categoria , idRef)
      {
        if (cat == categoria){
          SetCat(null);
        }else{
          SetCat(categoria);
          getSubCategorias(idRef);
        }
        SetLoaded(0);
      }
      
      function buscarSubCat(categoria , idRef)
      {
        SetCatPri(idRef);
        getSubCategorias(idRef);
        SetLoaded(0);
      }

        if(buscar)
        {
          filtrarItems(buscar);
        }

        filtrarItemsAdv()

        if(cat)
        {
          getCategorias();
          getProductosCategoria(cat).then(() => {
            
            SetLoaded(1);
          });
          //console.log('sdfsdf');
        }else{
          getCategorias().then(() => {
              
              getSubCategorias(catPri ? catPri : CATEGORIAS[0].id).then(() => {
                SetLoaded(1);
              })
          });
        }
        
      
    return(  
        <SafeAreaView style={{flex: 1 , backgroundColor: 'rgb(34,127,163)'}}>
          {filtros ? <>
                <View style={styles.filtros}>

                    <View style={styles.filtrosHeader}>
                      
                      <TouchableOpacity onPress={() => SetFiltros(filtros ? 0 : 1) }>
                            <Ionicons style={styles.filtroBack} name="arrow-back" size={21} color="#000000"/>
                      </TouchableOpacity>
                      <Text style={styles.filtrosTitulo}>Buscar</Text>
                    </View>
                    <View style={styles.filtrosBody}>
                      <Text style={styles.filtrosSubTitulo}>Nombre</Text>
                      <TextInput
                            placeholder="Buscar productos"
                            style={styles.input}
                            onChangeText={SetBuscar}
                            value={buscar}
                            />

                      <Text style={styles.filtrosSubTitulo}>Precio Mínimo</Text>
                      <TextInput
                          style={styles.input}
                          onChangeText={SetPrecioMinimo}
                          value={precioMinimo}
                          placeholder="Precio Mínimo"
                          // keyboardType="numeric"
                          inputMode="numeric"
                          />
                      
                      <Text style={styles.filtrosSubTitulo}>Precio Máximo</Text>
                      <TextInput
                          style={styles.input}
                          onChangeText={SetPrecioMaximo}
                          value={precioMaximo}
                          placeholder="Precio Máximo"
                          // keyboardType="numeric"
                          inputMode="numeric"
                        />
                      
                      <Text style={styles.filtrosSubTitulo}>Categorías</Text>
                      

                      <View>
                        <FlatList
                            data={CATEGORIAS}
                            keyExtractor={item => item.id}
                            // style={}
                            pagingEnabled
                            horizontal={true}
                            renderItem={({item}) => 
                              <TouchableOpacity 
                                    style={cat == item.nombre ? styles.buttonActivo : styles.button}
                                    onPress={() => 
                                      buscarCat(item.nombre , item.id)
                                      }>
                                      <Text style={cat == item.nombre ? styles.buttonTextActivo :  styles.buttonText}>{item.nombre}</Text>
                              </TouchableOpacity>
                            }
                          />
                      </View>
                      
                      <Text style={styles.filtrosSubTitulo}>Subcategorías</Text>
                      <View>
                        <FlatList
                            data={SUBCATEGORIAS}
                            keyExtractor={item => item.id}
                            // style={}
                            pagingEnabled
                            horizontal={true}
                            renderItem={({item}) => 
                              <TouchableOpacity 
                                    style={cat == item.nombre ? styles.buttonActivo : styles.button}
                                    onPress={() => 
                                      buscarCat(item.nombre , item.id)
                                      }>
                                      <Text style={cat == item.nombre ? styles.buttonTextActivo :  styles.buttonText}>{item.nombre}</Text>
                              </TouchableOpacity>
                            }
                          />
                      </View>

                    </View>
                </View>
          </> : <></>}
          <ImageBackground source={require('../img/mates.svg')} resizeMode="cover" style={styles.imageFondo}>
            <ScrollView style={styles.containerSafeArea}>
              <View style={styles.container}>
                
                <View style={styles.searchTop}>
                  
                  {/* <View style={styles.searchTopInput}>
                    <Ionicons style={styles.searchIcon} name="search" size={20} color="#0400b6"/>
                    <TextInput
                      placeholder="Buscar productos o tiendas"
                      style={styles.inputBuscar}
                      onChangeText={SetBuscar}
                      value={buscar}
                    />
                  </View> */}
                  
                    {cat ? <>
                      <View>
                        <TouchableOpacity onPress={() => resetCat()}>
                            <Ionicons style={styles.heartIcon} name="arrow-back" size={21} color="#ffffff"/>
                        </TouchableOpacity>
                      </View>
                      <View>
                        <Text style={styles.tituloPrincipal}>{cat}</Text>
                      </View>
                    </> : <>
                      <View>
                        
                      </View>
                      <View>
                        <Text style={styles.tituloPrincipal}>Categorías</Text>
                      </View>
                    </>}

                  
                      <TouchableOpacity onPress={() => navigation.navigate('LoginForm')}>
                          <Ionicons style={styles.heartIcon} name="log-in" size={21} color="#ffffff"/>
                      </TouchableOpacity>
                  
                  
                </View>

                  {loaded ? <>

                    {cat ? <>

                      <View style={styles.searchTopInput}>
                        <Ionicons style={styles.searchIcon} name="search" size={20} color="#ffffff"/>
                        <TextInput
                          placeholder="Buscar productos"
                          style={styles.inputBuscar}
                          onChangeText={SetBuscar}
                          value={buscar}
                        />
                      </View>
                      
                      <FlatList
                        data={SUBCATEGORIAS}
                        keyExtractor={item => item.id}
                        style={styles.buttonContainer}
                        horizontal={true}
                        pagingEnabled
                        renderItem={({item}) => 
                          <TouchableOpacity 
                                style={cat == item.nombre ? styles.buttonActivo : styles.button}
                                onPress={() => 
                                  buscarCat(item.nombre , item.id)
                                  }>
                                  <Text style={cat == item.nombre ? styles.buttonTextActivo :  styles.buttonText}>{item.nombre}</Text>
                          </TouchableOpacity>
                        }
                      />

                      <TouchableOpacity style={styles.buttonFiltro} onPress={() => SetFiltros(filtros ? 0 : 1) }>
                        <Ionicons style={{padding: 10}} name="funnel" size={20} color="#ffffff"/>
                        <Text style={styles.botonText}>
                          Buscar
                        </Text>
                      </TouchableOpacity>
                      

                      <FlatList
                      data={DATA}
                      renderItem={({item}) => <Item item={item} navigation={navigation}/>}
                      keyExtractor={item => item.id}
                      contentContainerStyle={styles.gridLista}
                      style={{width:'100%'}}
                      // numColumns={1}
                    />
                  </> : <>

                  <FlatList
                        data={CATEGORIAS}
                        keyExtractor={item => item.id}
                        style={{width: '80%' , backgroundColor: '#ffffff'}}
                        contentContainerStyle={{justifyContent: 'space-between' , flexGrow: 1}}
                        horizontal={true}
                        pagingEnabled
                        renderItem={({item}) => 
                          <TouchableOpacity 
                                // style={cat == item.nombre ? styles.buttonActivo : styles.button}
                                style={ styles.buttonCatPrincipal }
                                onPress={() => 
                                  buscarSubCat(item.nombre , item.id)
                                  }>
                                  <Text style={cat == item.nombre ? styles.buttonTextActivo :  styles.buttonText}>{item.nombre}</Text>
                          </TouchableOpacity>
                        }
                      />
                      <FlatList
                        data={SUBCATEGORIAS}
                        keyExtractor={item => item.id}
                        style={{width: '80%'}}
                        numColumns={2}
                        renderItem={({item}) => 
                          <TouchableOpacity 
                                style={styles.buttonCategoria}
                                onPress={() => 
                                  buscarCat(item.nombre , item.id)
                                  }>
                                    <ImageBackground source={{uri:  item.image}} resizeMode="cover" style={styles.imageFondoCat}>
                                      <Text style={styles.textCategoria}>{item.nombre}</Text>
                                    </ImageBackground>
                          </TouchableOpacity>
                        }
                      />
                        
                  </>}
                  
                  </> : <>
                        <Text>Cargando...</Text>
                  </>}
                  
              
                
              </View>
            </ScrollView>
            
          </ImageBackground>

        </SafeAreaView>
      );
}

export default ProductosLista;