import React from "react";
import { ImageBackground , StyleSheet ,View , Image , TextInput , FlatList , ScrollView , TouchableOpacity , Text  } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import BotonesTienda from "../components/BotonesTienda";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Firebase from "../db/Firebase";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import * as ImagePicker from 'expo-image-picker';
import { doc, setDoc , getDoc , getDocs , updateDoc , addDoc , collection , Timestamp , limit , query , where, Firestore , orderBy , deleteDoc} from "firebase/firestore";

const styles = StyleSheet.create({
    container:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 50,
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeImg: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#0400b6',
        borderWidth: 1,
        color: '#0400b6',
        borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '500px',
    },
    buttonGrandeOutlineTexto: {
        color: '#0400b6',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    itemLista:{
        marginBottom: 30,
        flexDirection: 'row',
        width: '100%',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#ececec',
        borderRadius: 5,
    },
    btnImagen:{
        width: '50%',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        backgroundColor: '#ececec',
        borderWidth: 0,
        // borderRadius: 30,
        marginBottom: 10,
        width: '100%',
        // maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    imagenLista:{
        height: 150,
        width: '100%',
        borderRadius: 5,
    },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
        color: '#ffffff',
      },
    logo: {
        width: 100,
        height: 187,
        marginBottom: 80,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      buttonPequeno: {
        padding: 5,
        textAlign: 'center',
        marginBottom: 10,
        width:'100%',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
        // borderWidth: 1,
        // borderColor: '#0400b6',
        // borderRadius: 30,
      },
      buttonPequenoTexto: {},
      buttonContainer: {
        // flex: 1,
        // flexDirection: 'row',
        width: '100%',
        maxWidth: '500px',
        marginBottom: 10,
        paddingVertical: 8,
        marginHorizontal: 'auto',
      },
      containerLista:{
        flexDirection: 'col',
        marginTop: 15,
        marginBottom: 5,
        padding: 10,
        width: '50%',
      },
      tituloLista: {
        fontWeight: 'bold',
        fontSize: 18,
        textAlign: 'left',
        width: '100%',
        color: 'rgb(34,127,163)',
      },
      precioLista: {
        fontWeight: 'bold',
        textAlign: 'left',
        width: '100%',
        fontSize: 16,
        marginTop: 5,
        color: 'rgb(34,127,163)',
      },
      button: {
        // display: 'inline-block',
        flex : 1,
        borderStyle: 'solid',
        borderColor: '#8a8a8a',
        backgroundColor: '#ffffff',
        borderWidth: 1,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        borderRadius: 30,
        marginRight: 10,
        // marginLeft: 4,
    },
    buttonActivo: {
        // display: 'inline-block',
        flex : 1,
        borderStyle: 'solid',
        borderColor: '#8a8a8a',
        backgroundColor: '#0400b6',
        borderWidth: 1,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        borderRadius: 30,
        marginRight: 10,
        // marginLeft: 4,
    },
    buttonText:{
      color: '#8a8a8a',
      fontSize: 17,
    },
    buttonTextActivo:{
      color: '#ffffff',
      fontSize: 17,
    },
    imgSlot:{
        width: '90%',
        marginRight: 5,
        height: '100%',
        alignItems: 'end',
    },
    searchTopInput: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        width: '100%',
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        padding: 5,
        borderRadius: 5,
        marginTop: 'auto',
        marginBottom: 'auto',
        maxWidth: 500,
        marginHorizontal : 'auto',
        marginBottom: 15,
  
      },
      searchIcon: {
        // marginTop: 15,
        // width: 20,
        padding: 5,
        borderRightColor: '#0400b6',
        borderRightWidth: 1,
        borderStyle: 'solid',
    },
    inputBuscar: {
        // padding: 14,
        // borderRadius: 5,
        // marginBottom: 5,
        // marginTop: 15,
        paddingLeft: 10,
        marginLeft: 'auto',
        width: '100%',
    },
    imageFondo: {
        backgroundColor: 'rgb(34,127,163)',
    },
    containerForm: {
        backgroundColor: '#ffffff',
        width: '100%',
    }
});


console.log(userId)

const DATA = [];
const CATEGORIAS = [];
const SUBCATEGORIAS = [];


async function getCategorias(buscar = null)
{
    const q = query(collection(Firebase, "categorias") , where('padre' , '==' , null) , orderBy('nombre'));

    const querySnapshot = await getDocs(q);
    
    CATEGORIAS.length = 0;
    
    querySnapshot.forEach((doc) => {
        CATEGORIAS.push({
            id: doc.id, ...doc.data()

        });
        
    });

    return CATEGORIAS;
}
async function getSubCategorias(idRef)
{
    const q = query(collection(Firebase, "categorias") , where('padre' , '==' , idRef) , orderBy('nombre'));

    const querySnapshot = await getDocs(q);
    
    SUBCATEGORIAS.length = 0;
    
    querySnapshot.forEach((doc) => {
        SUBCATEGORIAS.push({
            id: doc.id, 
            ...doc.data()

        });
      
    });

    return SUBCATEGORIAS;
}

async function getSubCategoriasPorNombre(nombre)
{
    let idRef;
    const qRef = query(collection(Firebase, "categorias") , where('nombre' , '==' , nombre) , where('padre' , '==' , null) , limit(1));
    const snapRef = await getDocs(qRef);

    snapRef.forEach((doc) => {
        idRef = doc.id;
    })
    
    if(idRef)
    {
        await getSubCategorias(idRef);
    }
}

let docRef = null;
let docSnap = null;
let userRef = null;
let userId = null ;

async function dataFn() {
    userId = await AsyncStorage.getItem('authToken');
    docRef = userId == '' || userId == null  
    ? null 
    : doc(Firebase , 'users' , userId);
    docSnap = userId == '' || userId == null  
        ? null 
        : await getDoc(docRef);
    userRef = userId == '' || userId == null  
        ? {} 
        : docSnap.data();
}

dataFn()


async function pickImage()
{
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
  
      
  
      if (!result.canceled) {
        
        return result.assets[0].uri
      }

      return null

}

async function crearProducto(data , image , image2 , image3 , image4 , image5 , productoId = null){
    try{

        let nombreImagen = '';
        let storage = '';
        let storageRef = '';

        if(image && image.search('https://') == -1)
        {
            nombreImagen = 'productos/'+userId+'/producto'+ data.nombre +'.jpg';
            storage = getStorage();
            storageRef = ref(storage, nombreImagen);
            
            await uploadString(storageRef, image, 'data_url').then(async (snapshot) => {
                await getDownloadURL(storageRef).then((url) => {
                    data.image = url;
                })
            });
        }
        
        if(image2 && image2.search('https://') == -1)
        {
            nombreImagen = 'productos/'+userId+'/producto'+ data.nombre +'-2.jpg';
            storage = getStorage();
            storageRef = ref(storage, nombreImagen);
            
            await uploadString(storageRef, image2, 'data_url').then(async (snapshot) => {
                await getDownloadURL(storageRef).then((url) => {
                    data.image2 = url;
                })
            });
        }
        
        if(image3 && image3.search('https://') == -1)
        {
            nombreImagen = 'productos/'+userId+'/producto'+ data.nombre +'-3.jpg';
            storage = getStorage();
            storageRef = ref(storage, nombreImagen);
            
            await uploadString(storageRef, image3, 'data_url').then(async (snapshot) => {
                await getDownloadURL(storageRef).then((url) => {
                    data.image3 = url;
                })
            });
        }
        
        if(image4 && image4.search('https://') == -1)
        {
            nombreImagen = 'productos/'+userId+'/producto'+ data.nombre +'-4.jpg';
            storage = getStorage();
            storageRef = ref(storage, nombreImagen);
            
            await uploadString(storageRef, image4, 'data_url').then(async (snapshot) => {
                await getDownloadURL(storageRef).then((url) => {
                    data.image4 = url;
                })
            });
        }
        
        if(image5 && image5.search('https://') == -1)
        {
            nombreImagen = 'productos/'+userId+'/producto'+ data.nombre +'-5.jpg';
            storage = getStorage();
            storageRef = ref(storage, nombreImagen);
            
            await uploadString(storageRef, image5, 'data_url').then(async (snapshot) => {
                await getDownloadURL(storageRef).then((url) => {
                    data.image5 = url;
                })
            });
        }
        

        data.userId = userId;

        var newDocument = collection(Firebase, "productos");
        if(productoId)
        {
            await updateDoc(doc(Firebase, "productos", productoId),
                data,
            );
        }else{
            await addDoc(newDocument, data).then(() => {
                // getProductos();
            });
        }


    }catch(error){
    
    }finally{
    
    }
}


async function getProductos()
{
    const q = query(collection(Firebase, "productos") , where('userId' , '==' , userId));

    const querySnapshot = await getDocs(q);
    
    DATA.length = 0;
    
    querySnapshot.forEach((doc) => {
        DATA.push({
            id: doc.id, ...doc.data()

        });
        
    });
}





export default function ProductosTienda({navigation}){
   
    const [loaded , SetLoaded] = React.useState(0);
    const [buscar , SetBuscar] = React.useState('');
    const [editar , SetEditar] = React.useState(0);
    const [nombreProducto , SetNombreProducto] = React.useState('');
    const [tipoProducto , SetTipoProducto] = React.useState('');
    const [categoriaProducto , SetCategoriaProducto] = React.useState('');
    const [subCategoriaProducto , SetSubCategoriaProducto] = React.useState('');
    const [descripcionProducto , SetDescripcionProducto] = React.useState('');
    const [precioProducto , SetPrecioProducto] = React.useState('');
    const [image, SetImage] = React.useState(null);
    const [image2, SetImage2] = React.useState(null);
    const [image3, SetImage3] = React.useState(null);
    const [image4, SetImage4] = React.useState(null);
    const [image5, SetImage5] = React.useState(null);
    const [productoId, SetProductoId] = React.useState(null);

    const [menuPos, setMenuPos] = React.useState('translateX(-100%)');
    // console.log(menuPos);
    function toggleMenu()
    {
        if(menuPos == 'translateX(-100%)')
        {
        setMenuPos('translateX(0%)');
        }else{
        setMenuPos('translateX(-100%)');
        }
    }

    function SetCatProducto(item)
    {
        SetCategoriaProducto(item.nombre)
        getSubCategorias(item.id)
        SetLoaded(0)
    }

    function nuevoProducto()
    {
        getCategorias().then(() => {
            SetEditar(1)
            SetLoaded(0)
        })
        
    }

    function editarProducto(item)
    {
        getCategorias()
        if(item.categoria)
        {
            getSubCategoriasPorNombre(item.categoria).then(() => {
                SetLoaded(0)
            })
            
        }

        SetNombreProducto(item.nombre ? item.nombre : '')
        SetDescripcionProducto(item.descripcion ? item.descripcion : '')
        SetPrecioProducto(item.precio ? item.precio : '')
        SetImage(item.image ? item.image : '')
        SetImage2(item.image2 ? item.image2 : '')
        SetImage3(item.image3 ? item.image3 : '')
        SetImage4(item.image4 ? item.image4 : '')
        SetImage5(item.image5 ? item.image5 : '')
        SetTipoProducto(item.tipo ? item.tipo : '')
        SetCategoriaProducto(item.categoria ? item.categoria : '')
        SetSubCategoriaProducto(item.subcategoria ? item.subcategoria : '')
        
        SetEditar(1)
        SetLoaded(0)
        SetProductoId(item.id)
    }
    
    async function eliminarProducto(item)
    {
        SetLoaded(0)

        await deleteDoc(doc(Firebase, "productos", item.id));
        
        getProductos().then(() => {
            SetLoaded(1)
        })
        
    }

    function resetForm()
    {
        SetNombreProducto('')
        SetDescripcionProducto('')
        SetPrecioProducto('')
        SetImage('')
        SetTipoProducto('')
        SetCategoriaProducto('')
        SetSubCategoriaProducto('')
        SetImage(null)
        SetImage2(null)
        SetImage3(null)
        SetImage4(null)
        SetImage5(null)
        SetProductoId(null)
    }

    function filtrarItems(buscar)
      {
        let filtrado = DATA.filter((el) => el.nombre.toLowerCase().includes(buscar.toLowerCase()))

        DATA.length = 0;
        DATA.push(...filtrado);

        //console.log(filtrado);
        // SetLoaded(0);
      }

    if(buscar)
    {
        filtrarItems(buscar);
        getProductos();
    }

    if(loaded == 0)
    {
        // getProductos().then(() => {
        //     SetLoaded(1)
        // });

        dataFn().then(() => {
            getProductos().then(() => {
                    SetLoaded(1)
                });
        })
    }

    
    
    
    return (
        <View style={{flex: 1 , backgroundColor: 'rgb(34,127,163)'}}>
            <ImageBackground source={require('../img/mates.svg')} resizeMode="cover" style={styles.imageFondo}>
                <ScrollView style={styles.container}>
                    <View style={{flexDirection: 'row' , alignItems: 'center', justifyContent: 'space-between' , width: '80%' , marginRight: 'auto' , marginLeft : 'auto'}}>
                                    <Text style={styles.titulo}>Productos</Text>
                                    <TouchableOpacity onPress={toggleMenu}>
                                        <Ionicons style={styles.heartIcon} name="menu" size={21} color="#ffffff"/>
                                    </TouchableOpacity>
                    </View>
                <View style={{width: '100%' , marginLeft: 'auto' , marginRight : 'auto'}}>
                    
                    {userRef.estatus == 1 ? <>
                            { editar ? <>
                                <View style={{width: '100%' , backgroundColor: '#ececec'}}>
                                    <TouchableOpacity style={styles.buttonPequeno} onPress={() => {
                                            SetEditar(0)
                                            resetForm()
                                            }}>
                                        <Text style={styles.buttonPequenoTexto} ><Ionicons name="arrow-back" size={20} color="#0400b6"></Ionicons> Volver al listado</Text>
                                    </TouchableOpacity>
                                </View>
                            
                            <View style={styles.containerForm}>
                                <View style={{width: '80%' , marginLeft : 'auto' , marginRight: 'auto'}}>
                                    <View style={{ flexDirection: 'row' , maxWidth: '500px',  width: '100%', marginHorizontal : 'auto' , overflow : 'auto'}}>
                                        <View style={styles.imgSlot}>
                                            {image && <Image source={{ uri: image }} style={{ width: '100%', height: 200 , borderRadius: 30, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />}
                                            <TouchableOpacity style={styles.buttonGrandeImg}>
                                                <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((image) => {SetImage(image)})}>
                                                    Seleccionar Imagen
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={styles.imgSlot}>
                                            {image2 && <Image source={{ uri: image2 }} style={{ width: '100%', height: 200 , borderRadius: 30, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />}
                                            <TouchableOpacity style={styles.buttonGrandeImg}>
                                                <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((image) => {SetImage2(image)})}>
                                                    Seleccionar Imagen
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={styles.imgSlot}>
                                            {image3 && <Image source={{ uri: image3 }} style={{ width: '100%', height: 200 , borderRadius: 30, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />}
                                            <TouchableOpacity style={styles.buttonGrandeImg}>
                                                <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((image) => {SetImage3(image)})}>
                                                    Seleccionar Imagen
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={styles.imgSlot}>
                                            {image4 && <Image source={{ uri: image4 }} style={{ width: '100%', height: 200 , borderRadius: 30, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />}
                                            <TouchableOpacity style={styles.buttonGrandeImg}>
                                                <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((image) => {SetImage4(image)})}>
                                                    Seleccionar Imagen
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={styles.imgSlot}>
                                            {image5 && <Image source={{ uri: image5 }} style={{ width: '100%', height: 200 , borderRadius: 30, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />}
                                            <TouchableOpacity style={styles.buttonGrandeImg}>
                                                <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((image) => {SetImage5(image)})}>
                                                    Seleccionar Imagen
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>

                                    <Text>Nombre</Text>
                                    <TextInput 
                                        style={styles.input}
                                        onChangeText={SetNombreProducto}
                                        value={nombreProducto}
                                        placeholder="Nombre del Producto"
                                    />

                                    <Text>Tipo</Text>
                                    <TextInput 
                                        style={styles.input}
                                        onChangeText={SetTipoProducto}
                                        value={tipoProducto}
                                        placeholder="Tipo de Producto"
                                    />
                                    {/* <TextInput 
                                        style={styles.input}
                                        onChangeText={SetCategoriaProducto}
                                        value={categoriaProducto}
                                        placeholder="Categoría del Producto"
                                    /> */}

                                    <Text style={styles.input}>Categoría: {categoriaProducto}</Text>

                                    <FlatList
                                        data={CATEGORIAS}
                                        keyExtractor={item => item.id}
                                        style={styles.buttonContainer}
                                        horizontal={true}
                                        renderItem={({item}) => 
                                        <TouchableOpacity 
                                                style={categoriaProducto == item.nombre ? styles.buttonActivo : styles.button}
                                                onPress={() => 
                                                SetCatProducto(item)
                                                }>
                                                <Text style={categoriaProducto == item.nombre ? styles.buttonTextActivo :  styles.buttonText}>{item.nombre}</Text>
                                        </TouchableOpacity>
                                        }
                                    />

                                    <Text style={styles.input}>Subcategoría: {subCategoriaProducto}</Text>
                                        
                                    <FlatList
                                        data={SUBCATEGORIAS}
                                        keyExtractor={item => item.id}
                                        style={styles.buttonContainer}
                                        horizontal={true}
                                        renderItem={({item}) => 
                                        <TouchableOpacity 
                                                style={subCategoriaProducto == item.nombre ? styles.buttonActivo : styles.button}
                                                onPress={() => 
                                                SetSubCategoriaProducto(item.nombre)
                                                }>
                                                <Text style={subCategoriaProducto == item.nombre ? styles.buttonTextActivo :  styles.buttonText}>{item.nombre}</Text>
                                        </TouchableOpacity>
                                        }
                                    />

                                    <Text>Precio</Text>
                                    <TextInput 
                                        style={styles.input}
                                        onChangeText={SetPrecioProducto}
                                        value={precioProducto}
                                        inputMode="numeric"
                                        keyboardType="numeric"
                                        placeholder="Precio del Producto"
                                    />

                                    <Text>Descripción</Text>
                                    <TextInput 
                                        multiline
                                        numberOfLines={4}
                                        style={styles.input}
                                        onChangeText={SetDescripcionProducto}
                                        value={descripcionProducto}
                                        placeholder="Descripción del Producto"
                                    />

                                    <TouchableOpacity style={styles.buttonGrande} onPress={() => crearProducto({
                                        nombre : nombreProducto,
                                        tipo: tipoProducto,
                                        categoria: categoriaProducto,
                                        subcategoria: subCategoriaProducto,
                                        precio: precioProducto,
                                        descripcion: descripcionProducto,
                                        fechaCreado: Timestamp.fromDate(new Date()),

                                    } , image , image2 , image3 , image4 , image5 , productoId).then(() => {
                                        SetEditar(0)
                                        SetLoaded(0)
                                    })}>
                                        <Text style={styles.buttonGrandeTexto} >{ productoId ? 'Actualizar' : 'Crear'} Producto</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>

                        </> :  <>
                                    
                            <View style={{width: '80%' , marginLeft: 'auto' , marginRight : 'auto'}}>
                                <TouchableOpacity style={styles.buttonPequeno} onPress={() => {
                                        nuevoProducto()
                                    }}>
                                    <Text><Ionicons name="add-circle" size={20} color="#0400b6"></Ionicons> Crear Producto</Text>
                                </TouchableOpacity>
                                {loaded ? <>

                                    <View style={styles.searchTopInput}>
                                        <Ionicons style={styles.searchIcon} name="search" size={20} color="#0400b6"/>
                                        <TextInput
                                        placeholder="Buscar productos"
                                        style={styles.inputBuscar}
                                        onChangeText={SetBuscar}
                                        value={buscar}
                                        />
                                    </View>
                                    <FlatList
                                    data={DATA}
                                    // numColumns={2}
                                    renderItem={({item}) => 
                                        <View style={styles.itemLista}>
                                            <TouchableOpacity 
                                                onPress={() => editarProducto(item)} 
                                                onLongPress={() => eliminarProducto(item)}
                                                style={styles.btnImagen} 
                                            >
                                                <Image source={{ uri: item.image }} style={styles.imagenLista}/>
                                            </TouchableOpacity>
                                            <View style={styles.containerLista}>
                                                <Text style={styles.tituloLista} onPress={() => editarProducto(item)}>{item.nombre}</Text>
                                                <Text style={styles.precioLista} onPress={() => editarProducto(item)}>${item.precio}</Text>
                                                <Text style={styles.descripcionLista}>{item.descripcion.substring(0,70)}...</Text>
                                            </View>

                                        </View>
                                    }
                                    keyExtractor={item => item.id}
                                    contentContainerStyle={styles.gridLista}
                                    
                                    />
                                </> :<></>}
                            </View>
                            
                        </> }
                    </> : <>
                                <Image
                                    style={styles.logo}
                                    source={ require('../img/logo.png')}
                                    />
                                <Text style={{maxWidth: 500, marginHorizontal: 'auto', textAlign: 'center'}}>Para cargar productos su tienda debe estar activada</Text>
                    </>} 
                </View>
                    


                </ScrollView>

                <BotonesTienda navigation={navigation} toggleMenu={toggleMenu} menuPos={menuPos}/>
            </ImageBackground>
        </View>
    );
}