import React from "react";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import TiendasAdmin from "./TiendasAdmin";
import UsersAdmin from "./UsersAdmin";
import PerfilAdmin from "./PerfilAdmin";
import VentasAdmin from "./VentasAdmin";
import CategoriasAdmin from "./CategoriasAdmin";
import Notificacion from "../views/Notificacion";

const Stack = createNativeStackNavigator();

export default function HomeAdmin({navigation , reload}){
    return(
        <NavigationContainer>
            <Stack.Navigator>
                <Stack.Screen name="UsersAdmin" initialParams={{ reload: reload }} component={UsersAdmin}  options={{headerShown: false}} />
                <Stack.Screen name="TiendasAdmin" initialParams={{ reload: reload }} component={TiendasAdmin}  options={{headerShown: false}} />
                <Stack.Screen name="VentasAdmin" initialParams={{ reload: reload }} component={VentasAdmin}  options={{headerShown: false}} />
                <Stack.Screen name="CategoriasAdmin" initialParams={{ reload: reload }} component={CategoriasAdmin}  options={{headerShown: false}} />
                <Stack.Screen name="PerfilAdmin" initialParams={{ reload: reload }} component={PerfilAdmin}  options={{headerShown: false}} />
                <Stack.Screen name="Notificacion" initialParams={{ reload: reload }} component={Notificacion}  options={{headerShown: false}} />
            </Stack.Navigator>
        </NavigationContainer>
    );
}