import React , {useState} from "react";
import Ionicons from '@expo/vector-icons/Ionicons';
import { ImageBackground , StyleSheet ,View , Image , TextInput , ScrollView , TouchableOpacity , Text } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as ImagePicker from 'expo-image-picker';
import { doc, getDoc , updateDoc} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import Firebase from "../db/Firebase";
import BotonesAdmin from "../components/BotonesAdmin";

const styles = StyleSheet.create({
    container:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 50,
    },
    imageFondo: {
        flex: 1,
      },
      containerSafeArea: {
        // backgroundColor: '#ffffff',
        flex: 1,
        marginTop: 0,
        // minHeight: '100vh',
      },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        marginBottom: 10,
        width: '80%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#ffffff',
        borderColor: '#0400b6',
        borderWidth: 1,
        color: '#0400b6',
        borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutlineTexto: {
        color: '#0400b6',
        textAlign: 'center',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        backgroundColor: '#ececec',
        borderWidth: 0,
        // borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        // maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
        color: '#ffffff',
      },
    logo: {
        width: 250,
        height: 250,
        marginBottom: 20,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
    container50 : { 
        width: '50%',
    },
      imgStyle : { 
        width: 100, 
        height: 100 , 
        borderRadius: 200, 
        marginRight: 'auto' , 
        marginLeft: 'auto' , 
        marginTop: 20,
        marginBottom: 20,
    }
});

function LogoutFn(reload)
{
    AsyncStorage.setItem('authToken', '');
    AsyncStorage.setItem('tipoUser', '');
    // window.location.reload(false);
    reload('' , '' , '')
}

let authToken = null;
let docRef = null;
let docSnap = null;
let userRef = null;

async function authFn(){
    authToken = await AsyncStorage.getItem('authToken');

    docRef = authToken == '' || authToken == null  
        ? null 
        : doc(Firebase , 'users' , authToken);
    docSnap = authToken == '' || authToken == null  
        ? null 
        : await getDoc(docRef);
    userRef = authToken == '' || authToken == null  
        ? {} 
        : docSnap.data();

    return authToken;
}

async function guardarDatos(data , image = null , navigation)
{
    if(image && image.search('https://') == -1)
    {
        const nombreImagen = 'perfiles/perfil-'+authToken+'.jpg';
        const storage = getStorage();
        const storageRef = ref(storage, nombreImagen);
        
        await uploadString(storageRef, image, 'data_url').then( async(snapshot) => {
            //console.log(snapshot.metadata.bucket + '/' + snapshot.metadata.fullPath);
            await getDownloadURL(storageRef).then((url) => {
                data.image = url;
                // //console.log(data);
            })
        });

        // data.image = nombreImagen;
    }

    await updateDoc(doc(Firebase, "users", authToken),
        data,
    );

    navigation.navigate('Notificacion' , {
        type: 'success',
        title: '¡Perfil actualizado!',
        message: 'Tus datos se han actualizado correctamente.'
    })

}

async function pickImage()
{
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
  
      //console.log(result);
  
      if (!result.canceled) {
        //console.log(result.assets[0].uri);
        return result.assets[0].uri
      }

      return null

}

authFn()

export default function PerfilAdmin({route , navigation}){
    const [loaded, SetLoaded] = React.useState(0);
    const [nombre, SetNombre] = React.useState(userRef.nombre);
    const [email, SetEmail] = React.useState(userRef.email);
    const [telefono, SetTelefono] = React.useState(userRef.telefono ? userRef.telefono : '');
    const [cedula, SetCedula] = React.useState(userRef.cedula ? userRef.cedula : '');
    const [image, setImage] = React.useState(userRef.image ? userRef.image : '');
    const [menuPos, setMenuPos] = useState('translateX(-100%)');
    // console.log(menuPos);
    function toggleMenu()
    {
        if(menuPos == 'translateX(-100%)')
        {
        setMenuPos('translateX(0%)');
        }else{
        setMenuPos('translateX(-100%)');
        }
    }

    if(!loaded)
    {
        authFn().then((val) => {
            if(val && val != '')
            {
                SetNombre(userRef.nombre)
                SetEmail(userRef.email)
                SetTelefono(userRef.telefono ? userRef.telefono : '')
                SetCedula(userRef.cedula ? userRef.cedula : '')
                setImage(userRef.image ? userRef.image : '')
                SetLoaded(1)
            }
        })
    }
    
    
    return (
        <View style={{flex: 1 , backgroundColor: 'rgb(34,127,163)'}}>
            <ImageBackground source={require('../img/mates.svg')} resizeMode="cover" style={styles.imageFondo}>
                <ScrollView style={styles.containerSafeArea}>
                
                    {loaded ? <>
                    
                        <View style={styles.container} >
                            <View style={{flexDirection: 'row' , alignItems: 'center', justifyContent: 'space-between' , width: '80%' , marginRight: 'auto' , marginLeft : 'auto'}}>
                                <Text style={styles.titulo}>Perfil</Text>
                                <TouchableOpacity onPress={toggleMenu}>
                                    <Ionicons style={styles.heartIcon} name="menu" size={21} color="#ffffff"/>
                                </TouchableOpacity>
                            </View>

                            {/* <Image
                            style={styles.logo}
                            source={ require('../img/logo.svg')}
                            /> */}

                            <View style={{backgroundColor: '#ececec', padding: 15}}>
                                <Text style={{color: 'rgb(34,127,163)' , textAlign:'center' , fontSize: 25}}>
                                    Cuenta
                                </Text>
                            </View>

                            
                            <View style={{backgroundColor: '#ffffff'}}>
                                <View style={{flexDirection: 'row' , alignItems: 'center' , justifyContent: 'space-between' , marginTop: 20 , marginBottom: 20 , width: '80%', marginRight: 'auto' , marginLeft: 'auto'}}>
                                    
                                    {image ? 
                                    
                                        <View style={styles.container50} >
                                            <Text style={{textAlign: 'center'}}>
                                                Foto de Perfil
                                            </Text>
                                            <Image 
                                            source={{ uri: image }} 
                                            style={styles.imgStyle} 
                                            /> 
                                            <TouchableOpacity>
                                                <Text style={{textAlign: 'center'}} onPress={() => pickImage().then((image) => {setImage(image)})}>
                                                    Cambiar Imagen
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    : <>
                                        <View style={styles.container50}  >
                                            <Text style={{textAlign: 'center'}}>
                                                Foto de perfil
                                            </Text>
                                            <Image 
                                            source={ require('../img/logo.svg')}
                                            style={styles.imgStyle} 
                                            /> 
                                            <TouchableOpacity>
                                                <Text style={{textAlign: 'center'}} onPress={() => pickImage().then((image) => {setImage(image)})}>
                                                    Agregar Imagen
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </>}
                                    
                                    <View style={styles.container50}>
                                        <Text>Nombre y apellido</Text>
                                        <TextInput 
                                            style={styles.input}
                                            placeholder="Nombre"
                                            onChangeText={SetNombre}
                                            value={nombre}
                                        />

                                        <Text>Cédula de identidad</Text>
                                        <TextInput 
                                            style={styles.input}
                                            placeholder="Cédula"
                                            onChangeText={SetCedula}
                                            value={cedula}
                                        />
                                    </View>

                                </View>
                                <View style={{width: '80%' , marginLeft: 'auto' , marginRight: 'auto'}}>
                                    <Text>Email</Text>
                                    <TextInput 
                                        style={styles.input}
                                        placeholder="Email"
                                        onChangeText={SetEmail}
                                        value={email}
                                    />
                                    
                                    <Text>Teléfono</Text>
                                    <TextInput 
                                        style={styles.input}
                                        placeholder="Teléfono"
                                        onChangeText={SetTelefono}
                                        value={telefono}
                                    />

                                    <TouchableOpacity style={{...styles.input , marginTop: 20}}>
                                        <Text style={styles.buttonGrandeOutlineTexto} onPress={() => {guardarDatos({   
                                                                nombre: nombre,
                                                                email: email,
                                                                telefono: telefono,
                                                                cedula: cedula,
                                                            } , image , navigation)}}>
                                            Guardar
                                        </Text>
                                    </TouchableOpacity>
                                    
                                    <TouchableOpacity style={{...styles.input , marginBottom: 20}}>
                                        <Text style={styles.buttonGrandeOutlineTexto} onPress={() => LogoutFn(route.params.reload)}>
                                            Cerrar Sesión
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                            </View>

                        </View>
                    
                    </> : <></>}
                </ScrollView>

                <BotonesAdmin navigation={navigation} toggleMenu={toggleMenu} menuPos={menuPos}/>
            </ImageBackground>

            
        </View>
    );
}