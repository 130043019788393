import React from "react";
import { StyleSheet , ImageBackground ,Text , View , TouchableOpacity , Image , TextInput , ScrollView} from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Firebase from "../db/Firebase";
import { doc, setDoc , getDocs , addDoc , collection , Timestamp , query , where, Firestore} from "firebase/firestore";
import SelectView from '../views/SelectView';

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      // backgroundColor: '#ececec',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
    },
    searchTop: {
      flexDirection: 'row',
      width: '80%',
      justifyContent: 'space-between',
      marginTop: 50,
      marginHorizontal: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      // backgroundColor: '#ffffff',
      borderRadius: 5,
    },
    tinyLogo: {
      width: 50,
      height: 50,
    },
    logo: {
      width: 287,
      height: 287,
      marginBottom: 80,
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        width: '80%',
        maxWidth: '300px',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
        textAlign: 'center',
        fontSize: 24,
    },
    buttonPequeno: {
        padding: 9,
        textAlign: 'center',
        color: '#0400b6',
        // borderStyle: 'solid',
        // borderColor: '#0400b6',
        // borderWidth: 1,
        // borderRadius: 30,
        marginTop: 10,
        marginBottom: 10,
        width: '80%',
        maxWidth: '260px',
        // backgroundColor: '#ffffff',
    },
    buttonPequenoTexto: {
        color: '#ffffff',
        fontSize: 16,
        textAlign: 'center',
    },
    textoExp:{
        color: '#ffffff',
        backgroundColor: '#0400b6',
        fontSize: 16,
        paddingVertical: 5,
        paddingHorizontal: 14,
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginBottom: 5,
        width: '80%',
        maxWidth: '300px',
        textAlign: 'center',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#0400b6',
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderRadius: 30,
        marginBottom: 5,
        fontSize: 16,
        color: '#9b9b9b',
        width: '80%',
        maxWidth: '300px',
    },
    inputBlue: {
      padding: 14,
      textAlign: 'center',
      backgroundColor: '#0400b6',
      color: '#ffffff',
      borderRadius: 30,
      marginBottom: 5,
      width: '100%',
      maxWidth: '300px',
    },
    imageFondo: {
      flex: 1,
      justifyContent: 'center',
    },
    boton: {
        padding: 14,
        textAlign: 'center',
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginBottom: 5,
        width: '50%',
        marginHorizontal: '2.5%',
        maxWidth: '300px',
    },
    containerSafeArea: {
      flex: 1,
      marginTop: 0,
    },
    botonBlue: {
      padding: 14,
      textAlign: 'center',
      backgroundColor: '#0400b6',
      color: '#ffffff',
      borderRadius: 30,
      marginBottom: 5,
      width: '50%',
      marginHorizontal: '2.5%',
      maxWidth: '300px',
    }
  });

  async function SetLogin(authToken, tipoUser , navigation , reload){
    //console.log(tipoUser);
    await AsyncStorage.setItem('authToken', authToken);
    await AsyncStorage.setItem('tipoUser', tipoUser.toString());
    // redireccionar
    reload(authToken , tipoUser , 2)
  }

  async function ValidateLogin(data , navigation , reload)
  {
    // const [userRef, SetUserRef] = useState('');
    let userRef = '';
    
    const consulta = query(collection(Firebase, "users"), where("email", "==", data.email) , where("password", "==", data.password));

    const querySnapshot = await getDocs(consulta);

    //console.log(querySnapshot);
    
    querySnapshot.forEach((doc) => {
      // //console.log(doc.id, " => ", doc.data());
      //console.log(doc.data());
      userRef = {
        id : doc.id,
        tipo: doc.data().tipo,
      };
    });

    if(userRef == '')
    {
      navigation.navigate('Notificacion' , {
        type: 'fail',
        title: '¡Email o contraseña inválida!',
        message: 'Por favor verifique los datos e intente nuevamente.'
      })
    }

    if(userRef)
    {//console.log(userRef);
      SetLogin(userRef.id , userRef.tipo , navigation , reload);
    }
  }

  async function Registrar(data , navigation , reload)
  {
    if(!data.email)
    {
      navigation.navigate('Notificacion' , {
        type: 'warning',
        title: '¡Datos requeridos!',
        message: 'el campo email es requerido.'
      })
      return true;
    }
    
    if(!data.cedula)
    {
      navigation.navigate('Notificacion' , {
        type: 'warning',
        title: '¡Datos requeridos!',
        message: 'el campo cédula es requerido.'
      })
      return true;
    }
    
    if(!data.password)
    {
      navigation.navigate('Notificacion' , {
        type: 'warning',
        title: '¡Datos requeridos!',
        message: 'el campo contraseña es requerido.'
      })
      return true;
    }
    
    if(data.password.length < 6)
    {
      navigation.navigate('Notificacion' , {
        type: 'warning',
        title: '¡Datos requeridos!',
        message: 'La contraseña debe tener mínimo 6 caracteres.'
      })
      return true;
    }
    
    if(!data.nombre)
    {
      navigation.navigate('Notificacion' , {
        type: 'warning',
        title: '¡Datos requeridos!',
        message: 'el campo nombre es requerido.'
      })
      return true;
    }

    if(data.nombre.length < 3)
    {
      navigation.navigate('Notificacion' , {
        type: 'warning',
        title: '¡Datos requeridos!',
        message: 'El nombre debe tener mínimo 3 caracteres.'
      })
      return true;
    }
    
    if(!data.telefono)
    {
      navigation.navigate('Notificacion' , {
        type: 'warning',
        title: '¡Datos requeridos!',
        message: 'el campo teléfono es requerido.'
      })
      return true;
    }

    if(data.telefono.length < 3)
    {
      navigation.navigate('Notificacion' , {
        type: 'warning',
        title: '¡Datos requeridos!',
        message: 'El teléfono debe tener mínimo 3 caracteres.'
      })
      return true;
    }

    try{
      var newDocument = collection(Firebase, "users");
      let estatus = data.tipo == 1 ? 1 : 0;
      //console.log(estatus);
      let verificarMail = 0;

      // verificar si existe Mail
      const consulta = query(collection(Firebase, "users"), where("email", "==", data.email));

      const querySnapshot = await getDocs(consulta);

      //console.log(querySnapshot);
      
      querySnapshot.forEach((doc) => {
        // //console.log(doc.id, " => ", doc.data());
        //console.log(doc.data());
        verificarMail ++;
        // console.log(verificarMail);
        
        
      });

      if(verificarMail > 0 )
      {
        navigation.navigate('Notificacion' , {
          type: 'warning',
          title: '¡Datos requeridos!',
          message: 'Este email ya está registrado.'
        })
        return true;
      }

      addDoc(newDocument, {
        email: data.email,
        nombre: data.nombre,
        cedula: data.cedula,
        telefono: data.telefono,
        password: data.password,
        tipo: data.tipo,
        estatus: estatus,
        fechaCreado: Timestamp.fromDate(new Date()),
      }).then((docRef) => {
        if(data.tipo == 2)
        {
            // var newTienda = doc(collection(Firebase, "tiendas"));
            var newTienda = collection(Firebase, "tiendas");
            addDoc(newTienda, {
              userId: docRef.id,
              fechaCreado: Timestamp.fromDate(new Date()),
              name: data.nombreTienda,
              about: data.descripcionTienda,
              picture: 'https://static.vecteezy.com/system/resources/previews/004/439/485/non_2x/store-cartoon-illustration-free-vector.jpg',
            }).then(() => {
              SetLogin( docRef.id ,data.tipo , navigation , reload);
            });
        }
        if(data.tipo == 1)
        {
          SetLogin(docRef.id ,data.tipo , navigation , reload);
        }
      });
    }catch(error){
      //console.log(error);
    }finally{
      
    }
    

  }

  function LoginForm({route , navigation}){
    // console.log(route.params.reload)
    const [register, SetRegister] = React.useState(false);
    const [nombre, SetNombre] = React.useState('');
    const [nacimiento, SetNacimiento] = React.useState('');
    const [cedula, SetCedula] = React.useState('');
    const [telefono, SetTelefono] = React.useState('');
    const [email, SetEmail] = React.useState('');
    const [password, SetPassword] = React.useState('');
    const [tipo, SetTipo] = React.useState(1);
    const [nombreTienda, SetNombreTienda] = React.useState('');
    const [descripcionTienda, SetDescripcionTienda] = React.useState('');
    const [imagenTienda, SetImagenTienda] = React.useState('');
    
      return(
          <View style={{flex: 1 , backgroundColor: 'rgb(34,127,163)'}}>
            
                <ImageBackground source={require('../img/mates.svg')} resizeMode="cover" style={styles.imageFondo}>
                        <View style={styles.searchTop}>
                          <View>
                            <TouchableOpacity onPress={() => navigation.goBack()}>
                                <Ionicons style={styles.arrowIcon} name="arrow-back" size={25} color="#ffffff"/>
                            </TouchableOpacity>
                          </View>
                        </View>
                    <ScrollView style={styles.containerSafeArea}>
                          <View style={styles.container}>
                          <Image
                          style={styles.logo}
                          source={ require('../img/logo.svg')}
                          />
                          
                          { register ? <>
                          
                            <View style={{flexDirection: 'row' , justifyContent: 'space-around' , maxWidth: '300px' , width: '80%'}}>

                              <TouchableOpacity style={ tipo == 1 ? styles.botonBlue : styles.boton} onPress={() => SetTipo(1)}>
                                <Text style={ tipo == 1 ? styles.buttonGrandeTexto : {textAlign: 'center'} }>Cliente</Text>
                              </TouchableOpacity>

                              <TouchableOpacity style={ tipo == 2 ? styles.botonBlue : styles.boton } onPress={() => SetTipo(2)}>
                                <Text style={ tipo == 2 ? styles.buttonGrandeTexto : {textAlign: 'center'} }>Tienda</Text>
                              </TouchableOpacity>

                            </View>

                            { tipo == 1 && <>
                              <TextInput 
                                style={styles.input}
                                onChangeText={SetNombre}
                                placeholder="Nombre"
                                value={nombre}
                                />
                                <TextInput 
                                style={styles.input}
                                onChangeText={SetCedula}
                                placeholder="Cédula de Identidad"
                                value={cedula}
                                />
                                <TextInput 
                                    style={styles.input}
                                    onChangeText={SetTelefono}
                                    value={telefono}
                                    placeholder="Teléfono"
                                />
                                <TextInput 
                                    style={styles.input}
                                    onChangeText={SetEmail}
                                    value={email}
                                    placeholder="Email"
                                />
                                <TextInput 
                                    secureTextEntry={true}
                                    style={styles.input}
                                    onChangeText={SetPassword}
                                    value={password}
                                    placeholder="Contraseña"
                                />
                          </> }

                            { tipo == 2 && <>

                            <Text style={styles.textoExp}>Datos del responsable</Text>
                              <TextInput 
                                style={styles.input}
                                onChangeText={SetNombre}
                                placeholder="Nombre"
                                value={nombre}
                                />
                              <TextInput 
                                style={styles.input}
                                onChangeText={SetCedula}
                                placeholder="Cédula de Identidad"
                                value={cedula}
                                />
                              <TextInput 
                                style={styles.input}
                                onChangeText={SetNacimiento}
                                placeholder="Fecha de nacimiento"
                                value={nacimiento}
                                />
                                
                                <TextInput 
                                    style={styles.input}
                                    onChangeText={SetEmail}
                                    value={email}
                                    placeholder="Email"
                                />
                                <TextInput 
                                    style={styles.input}
                                    onChangeText={SetTelefono}
                                    value={telefono}
                                    placeholder="Teléfono"
                                />
                                <TextInput 
                                    secureTextEntry={true}
                                    style={styles.input}
                                    onChangeText={SetPassword}
                                    value={password}
                                    placeholder="Contraseña"
                                />
                            <Text style={styles.textoExp}>Datos de la tienda</Text>
                            <TextInput 
                                style={styles.input}
                                onChangeText={SetNombreTienda}
                                value={nombreTienda}
                                placeholder="Nombre de la tienda"
                            />
                            <TextInput 
                                multiline
                                numberOfLines={4}
                                style={styles.input}
                                onChangeText={SetDescripcionTienda}
                                value={descripcionTienda}
                                placeholder="Descripción de la tienda"
                            />
                          </> }
                          
                          <TouchableOpacity style={styles.buttonGrande} onPress={() => Registrar({
                            nombre: nombre,
                            email: email,
                            password: password,
                            nacimiento: nacimiento,
                            cedula: cedula,
                            telefono: telefono,
                            tipo: tipo,
                            nombreTienda: nombreTienda,
                            descripcionTienda: descripcionTienda,
                          } , navigation , route.params.reload)}>
                            <Text style={styles.buttonGrandeTexto}>Registrar</Text>
                          </TouchableOpacity>
                          
                          <TouchableOpacity style={styles.buttonPequeno} onPress={() => SetRegister(false)}>
                            <Text style={styles.buttonPequenoTexto} >Iniciar Sesión</Text>
                          </TouchableOpacity>
                          
                            </> : <>
                            <TextInput 
                              style={styles.input}
                              onChangeText={SetEmail}
                              value={email}
                              placeholder="Email"
                          />
                          <TextInput 
                              secureTextEntry={true}
                              style={styles.input}
                              onChangeText={SetPassword}
                              value={password}
                              placeholder="Contraseña"
                          />
                        
                        <TouchableOpacity style={styles.buttonGrande} onPress={() => ValidateLogin({
                              email: email,
                              password: password,
                            } , navigation , route.params.reload)}>
                          <Text style={styles.buttonGrandeTexto}>Iniciar Sesión</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity style={styles.buttonPequeno} onPress={() => SetRegister(true)}>
                          <Text style={styles.buttonPequenoTexto} >Registrarse</Text>
                        </TouchableOpacity>
                          
                          </> }
                          </View>
                    </ScrollView>
                </ImageBackground>
          </View>
      );
  }

  export default LoginForm;