import React from "react";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeTienda from "./HomeTienda";
import PerfilTienda from "./PerfilTienda";
import ProductosTienda from "./ProductosTienda";
import VentasTienda from "./VentasTienda";
import Notificacion from "../views/Notificacion";
import ChatTienda from "./ChatTienda";
import PaymentForm from "../components/PaymentForm";

const Stack = createNativeStackNavigator();

export default function UserTienda({navigation , reload}){
    return(
        <NavigationContainer>
            <Stack.Navigator>
                <Stack.Screen name="HomeTienda" component={HomeTienda} initialParams={{ reload: reload }}  options={{headerShown: false}} />
                <Stack.Screen name="PerfilTienda" initialParams={{ reload: reload }} component={PerfilTienda}  options={{headerShown: false}} />
                <Stack.Screen name="ProductosTienda" initialParams={{ reload: reload }} component={ProductosTienda}  options={{headerShown: false}} />
                <Stack.Screen name="ChatTienda" initialParams={{ room: null }} component={ChatTienda}  options={{headerShown: false}} />
                <Stack.Screen name="VentasTienda" initialParams={{ reload: reload }} component={VentasTienda}  options={{headerShown: false}} />
                <Stack.Screen name="Notificacion" initialParams={{ reload: reload }} component={Notificacion}  options={{headerShown: false}} />
                <Stack.Screen name="PaymentForm" initialParams={{ reload: reload }} component={PaymentForm}  options={{headerShown: false}} />
            </Stack.Navigator>
        </NavigationContainer>
    );
}