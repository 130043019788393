import React from "react";
import { StyleSheet ,View , Image , TextInput , ScrollView , TouchableOpacity , FlatList , Text } from "react-native";
import BotonesAdmin from "../components/BotonesAdmin";
import Ionicons from '@expo/vector-icons/Ionicons';
import Firebase from "../db/Firebase";
import { doc, setDoc , getDoc , getDocs , updateDoc , addDoc , collection , Timestamp , query , where, Firestore} from "firebase/firestore";
import AsyncStorage from "@react-native-async-storage/async-storage";

const styles = StyleSheet.create({
    container:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    botonLista: {
        flexDirection: 'row' , 
        justifyContent: 'space-between',
        width: '100%',
        padding: 9,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#0400b6',
        marginBottom: 8,
        
      },
      botonListaTexto: {
        color: '#0400b6',
      },    
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#0400b6',
        borderWidth: 1,
        color: '#0400b6',
        borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutlineTexto: {
        color: '#0400b6',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        maxWidth: '300px',
    },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
      },
    logo: {
        width: 187,
        height: 187,
        marginBottom: 10,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
    buttonPequeno:{
        marginBottom: 10,
    },
    tituloOrden:{
        fontSize: 18,
        marginTop: 10,
        textAlign: 'center',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        backgroundColor: '#0400b6',
        padding: 8,
        color: '#ffffff',
        fontWeight: 'bold',
    },
    itemsLista: {
        flexDirection: 'row' , 
        justifyContent: 'space-between',
        borderBottomColor: '#0400b6',
        borderBottomWidth: 1,
        // marginTop: 8,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 8,
        paddingBottom: 8,
        backgroundColor: '#ffffff',
    },
    cardBlanca: {
        backgroundColor: '#ffffff',
        padding: 15,
        // marginTop: 8,
        // marginBottom: 8,
    },
});

let userId = null;

async function authFn() {
    userId = await AsyncStorage.getItem('authToken');
}

authFn()

const DATA = [];




export default function VentasAdmin({navigation}){
        const [orden , SetOrden] = React.useState('');
        const [vista , SetVista] = React.useState('lista');
        const [loaded , SetLoaded] = React.useState(0);

        const [menuPos, setMenuPos] = React.useState('translateX(-100%)');
        // console.log(menuPos);
        function toggleMenu()
        {
            if(menuPos == 'translateX(-100%)')
            {
            setMenuPos('translateX(0%)');
            }else{
            setMenuPos('translateX(-100%)');
            }
        }

        function estatusPublico(estatus)
        {
            switch (estatus) {
                case 1:
                    return 'Pendiente';
                    break;
                
                case 2:
                    return 'Aprobado';
                    break;
                
                case 0:
                    return 'Rechazado';
                    break;
            
                default:
                    return 'No Definido';
                    break;
            }
        }
        
        function estatusPublicoColor(estatus)
        {
            switch (estatus) {
                case 1:
                    return '#000000';
                    break;
                
                case 2:
                    return '#008f39';
                    break;
                
                case 0:
                    return '#ff0000';
                    break;
            
                default:
                    return '#000000';
                    break;
            }
        }

        function verOrden(item)
        {
            SetOrden(item)
            SetVista('ver')
            SetLoaded(0)
        }

        function resetForm()
        {
            SetOrden('')
            SetVista('lista')
            SetLoaded(0)
        }

        async function cambiarEstatus(orden , estatus)
        {
            await updateDoc(doc(Firebase, "ordenes", orden.id),
                {estatus: estatus},
            ).then(() => resetForm());
        }
        
        async function getCompras()
        {
            const q = query(collection(Firebase, "ordenes"));

            const querySnapshot = await getDocs(q);
            
            DATA.length = 0;
            
            querySnapshot.forEach((doc) => {
                  DATA.push({
                    id: doc.id, ...doc.data(),
                  });
               
            });
        }   

        getCompras().then(() => {
            SetLoaded(1);
        });
        
    return (
        <View style={{flex: 1 , backgroundColor: 'rgb(34,127,163)'}}>
            <ScrollView style={styles.container}>
                <View style={{width: '80%' , marginLeft: 'auto' , marginRight: 'auto'}}>
                    <View style={{flexDirection: 'row' , alignItems: 'center', justifyContent: 'space-between' , width: '80%' , marginRight: 'auto' , marginLeft : 'auto'}}>
                        <Text style={styles.titulo}>Ventas</Text>
                        <TouchableOpacity onPress={toggleMenu}>
                            <Ionicons style={styles.heartIcon} name="menu" size={21} color="#ffffff"/>
                        </TouchableOpacity>
                    </View>
                    {vista == 'lista' && <>
                        {loaded ? <>
                            <FlatList
                                data={DATA}
                                renderItem={({item}) => 
                                    <View>
                                        <TouchableOpacity style={styles.botonLista} onPress={() => verOrden(item)}>
                                            <Text style={styles.botonListaTexto}>{item.nombre}</Text>
                                            <Text style={styles.botonListaTexto}>${item.total}
                                                {item.estatus == 0 && <>
                                                    <Ionicons name="close-circle" size={20} color="#ff0000"></Ionicons>
                                                </>}
                                                {item.estatus == 1 && <>
                                                    <Ionicons name="information-circle" size={20} color="#ffff00"></Ionicons>
                                                </>}
                                                {item.estatus == 2 && <>
                                                    <Ionicons name="checkmark-circle" size={20} color="#008f39"></Ionicons>
                                                </>}
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                }
                                keyExtractor={item => item.id}
                                contentContainerStyle={styles.gridLista}
                                
                                />

                                {DATA.length == 0 ? <>
                                    <Ionicons style={{color:'#0400b6' , textAlign: 'center' , marginTop: 100}} name="cash" size={100} color="#0400b6"></Ionicons>
                                    <Text style={styles.titulo}>Aún no tienes ventas registradas</Text>
                                </> : <></>}
                        </> : <>
                            <Text>Cargando Ventas...</Text>
                        </>}
                    </>}

                    {vista == 'ver' && <>
                        <TouchableOpacity style={styles.buttonPequeno} onPress={() => {
                            resetForm()
                        }}>
                            <Text style={styles.buttonPequenoTexto} ><Ionicons name="arrow-back" size={20} color="#0400b6"></Ionicons> Volver al listado</Text>
                        </TouchableOpacity>   

                        <Image
                          style={styles.logo}
                          source={ require('../img/logo.svg')}
                          />
                        
                        <Text style={styles.tituloOrden}>Orden de compra</Text>
                        <View style={styles.cardBlanca}>
                            <Text>Nombre: {orden.nombre}</Text>
                            <Text>Email: {orden.email}</Text>
                            <Text>Teléfono: {orden.telefono}</Text>
                            <Text>Detalles: {orden.detalles}</Text>
                            <Text style={{ color: estatusPublicoColor(orden.estatus) }}>Estatus: { estatusPublico(orden.estatus)}</Text>
                        </View>
                        {/* <Text>Total: {orden.total}</Text> */}

                        { orden.productos && orden.productos.length && <>
                            <Text style={styles.tituloOrden}>Items</Text>
                            <View style={styles.itemsLista}>
                                <Text style={{width: '60%'}}>Nombre</Text>
                                <Text>Precio</Text>
                                <Text>Qty</Text>
                                <Text>Total</Text>
                            </View>
                        </> }

                        <FlatList
                        data={orden.productos}
                        renderItem={({item}) => 
                            <View style={styles.itemsLista}>     
                                <Text style={{width: '60%'}}>{item.nombre}</Text>
                                <Text>{item.precio}</Text>
                                <Text>{item.cantidad}</Text>
                                <Text>{item.precio * item.cantidad}</Text>
                            </View>
                        }
                        keyExtractor={item => item.productoId}
                        contentContainerStyle={styles.gridLista}
                        
                        />

                        { orden.productos && orden.productos.length && <>
                            <View style={styles.itemsLista}>
                                <Text style={{width: '60%'}}></Text>
                                <Text></Text>
                                <Text style={{fontWeight : 'bold'}}>Total:</Text>
                                <Text style={{fontWeight : 'bold'}}>${orden.total}</Text>
                            </View>
                        </> }

                        <View style={{flexDirection: 'row' , justifyContent: 'space-between' , marginVertical : 15}}>
                            <TouchableOpacity style={{width:'45%' , padding: 10 , backgroundColor: '#ff0000' , borderRadius: 15}} onPress={() => cambiarEstatus(orden , 0)}>
                                <Text style={{ textAlign : 'center' ,color: '#ffffff' , }}>Rechazar</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={{width:'45%' , padding: 10 , backgroundColor: '#008f39' , borderRadius: 15}} onPress={() => cambiarEstatus(orden , 2)}>
                                <Text style={{ textAlign : 'center' ,color: '#ffffff' , }}>Aprobar</Text>
                            </TouchableOpacity>
                        </View>
                    </>}
                </View>
            </ScrollView>

            <BotonesAdmin navigation={navigation} toggleMenu={toggleMenu} menuPos={menuPos}/>
        </View>
    );
}
