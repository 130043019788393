import React from "react";
import Ionicons from '@expo/vector-icons/Ionicons';
import { SafeAreaView , 
          ImageBackground , 
          StyleSheet , 
          FlatList,
          Text , 
          ScrollView,
          Image,
          View , 
          TouchableOpacity , 
          TextInput} from "react-native";
import db from "../db/Firestore";
// import Firebase from "../db/Firebase";
// import { doc, setDoc , getDocs , addDoc , collection , Timestamp , query , where, Firestore , orderBy, startAt, endAt , limit} from "firebase/firestore";
import AsyncStorage from '@react-native-async-storage/async-storage';

import Botones from "../components/Botones";


const styles = StyleSheet.create({
    container: {
      flex: 1,
      // backgroundColor: '#ececec',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageFondo: {
      flex: 1,
    },
    inputBuscar: {
        // padding: 14,
        // borderRadius: 5,
        // marginBottom: 5,
        // marginTop: 15,
        paddingLeft: 10,
        marginLeft: 'auto',
        width: '90%',
    },
    button: {
        // display: 'inline-block',
        flex : 1,
        borderStyle: 'solid',
        borderColor: '#8a8a8a',
        // backgroundColor: '#ffffff',
        borderWidth: 0,
        borderBottomWidth: 2,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        // borderRadius: 5,
        marginRight: 10,
        // marginLeft: 4,
    },
    buttonActivo: {
        // display: 'inline-block',
        flex : 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        // backgroundColor: '#0400b6',
        borderWidth: 0,
        borderBottomWidth: 2,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        // borderRadius: 5,
        marginRight: 10,
        // marginLeft: 4,
    },
    buttonText:{
      color: '#8a8a8a',
      fontSize: 17,
    },
    buttonTextActivo:{
      color: '#000000',
      fontSize: 17,
    },
    containerSafeArea: {
      flex: 1,
      marginTop: 0,
      // minHeight: '100vh',
    },
    gridLista : {
      width: '80%',
      // flexWrap: 'wrap',
      justifyContent: 'space-between',
    //   flexDirection: 'row',
      marginLeft: 'auto',
      marginRight: 'auto',
    //   flexGrow: 1,
    },
    itemLista : {
    //   width: '200px',
      width:'50%',
      justifyContent: 'space-between',
      paddingHorizontal: 5,
      marginTop: 10,
    },
    imagenLista: {
      width: '100%',
      height: 170,
      borderRadius: 5,
      marginTop: 5,
      marginBottom: 5,
    },
    titulo: {
      fontWeight: 'bold',
      fontSize: 23.5,
      margin: 20,
    },
    tituloLista: {
      fontWeight: 'bold',
    },
    precioLista: {
      fontWeight: 'bold',
      fontSize: 16,
      marginTop: 5,
      color: '#0400b6',
    },
    descripcionLista: {
      marginBottom: 15,
    },
    botonVer: {
      textAlign: 'right',
    },
    botonVerTexto: {
      color: '#0400b6',
      fontWeight: 'bold',
    },
    buttonContainer: {
      // flex: 1,
      // flexDirection: 'row',
      width: '80%',
      marginVertical: 10,
      paddingVertical: 8,
    },
    searchTop: {
      flexDirection: 'row',
      width: '80%',
      justifyContent: 'center',
      marginTop: 20,
      // backgroundColor: '#ffffff',
      borderRadius: 5,
    },
    searchTopInput: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      width: '90%',
      borderStyle: 'solid',
      borderColor: '#0400b6',
      borderWidth: 1,
      padding: 5,
      borderRadius: 5,
      marginTop: 'auto',
      marginBottom: 'auto',

    },
    searchIcon: {
        // marginTop: 15,
        // width: 20,
        padding: 5,
        borderRightColor: '#0400b6',
        borderRightWidth: 1,
        borderStyle: 'solid',
    },
    heartIcon: {
        padding: 10,
        // backgroundColor: '#0400b6',
        color: '#000000',
        borderRadius: 5,
        marginLeft: 5,
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    tituloPrincipal: {
      fontSize: 25,
      color: '#000000',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    tituloTienda : {
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#ffffff',
      fontSize: 15,
      marginBottom: 15,
    },
    textTienda : {
      color: '#ffffff',
    },
    imgTienda : {
      height: 50,
      width: '100%',
    },
    containerTitulo: {
      padding: 20,
      backgroundColor: '#0400b6',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '80%',
      flexDirection: 'row',
      borderRadius: 20,
      marginTop: 40,
      marginBottom: 10,
    },
    containerDescripcion: {
      // padding: 30,
      width: '80%',
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'justify',
    },
    textCategoria: {
      paddingHorizontal: 30,
      paddingVertical: 60,
      fontSize: 20,
      fontWeight: 'bold',
    },
    buttonCategoria: {
      // backgroundColor: '#0400b6',
      borderRadius: 30,
      marginBottom: 15,
      fontWeight: 'bold',
      overflow: 'hidden',
      shadowColor: '#0400b6',
      shadowOffset: {width: 0, height: 5},
      shadowOpacity: 0.1,
      shadowRadius: 10,
    }
  });

  let authToken = null;

  async function authFn() {
    authToken = await AsyncStorage.getItem('authToken');
  }

  authFn()

  const DATA = [];

  async function getFavoritos()
  {
    DATA.length = 0;
    
    await db.collection("favoritos")
            .where('userId' , '==' , authToken)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    DATA.push({
                      id : doc.id,
                      ... doc.data()
                    })
                    // //console.log(doc.data())
                });
            });
            //console.log(DATA)
  }
    
  const Item = ({item , navigation}) => (
    <View style={styles.itemLista}>
      <TouchableOpacity onPress={() => navigation.navigate('Producto' , {
        productoId : item.productoId,
        userId: item.tiendaId,
      })}>
        <Image source={{ uri: item.image }} style={styles.imagenLista}/>
      </TouchableOpacity>
      
      <View style={{ flexDirection: 'col' , justifyContent: 'space-between' , alignItems: 'center' , padding: 10,}}>
        <Text style={styles.tituloLista}>{item.nombre}</Text>
        <Text style={styles.precioLista}>${item.precio}</Text>
      </View>
      
    </View>
  );

  
// //console.log(use)
function Favoritos({route, navigation}){
      const [loaded , SetLoaded] = React.useState(0);
      const [buscar , SetBuscar] = React.useState('');

    if(loaded == 0)
    {
      getFavoritos()
      .then(() => {
          SetLoaded(1);
      });
    }
        
      
    return(  
        <View style={{flex: 1}}>
          <ImageBackground source={require('../img/fondo.jpg')} resizeMode="cover" style={styles.imageFondo}>
            <ScrollView style={styles.containerSafeArea}>
              <View style={styles.container}>
                
                <View style={styles.searchTop}>
                     
                        <Text style={styles.tituloPrincipal}>Favoritos</Text>
                                     
                </View>

              

                  {loaded ? <>

                    <FlatList
                      data={DATA}
                      renderItem={({item}) => <Item item={item} navigation={navigation}/>}
                      keyExtractor={item => item.id}
                      contentContainerStyle={styles.gridLista}
                      style={{width:'100%'}}
                      numColumns={2}
                    />
                  
                  </> : <>
                        <Text>Cargando...</Text>
                  </>}
                  
              
                
              </View>
            </ScrollView>
                      
            { authToken == null || authToken == '' ? <>
            
            </> : <>
                <Botones navigation={navigation}/>      
            </> }
          </ImageBackground>

        </View>
      );
}

export default Favoritos;