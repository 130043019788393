import React from "react";
import { StyleSheet ,View , Image , TextInput , FlatList , ScrollView , TouchableOpacity , Text  } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import BotonesAdmin from "../components/BotonesAdmin";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Firebase from "../db/Firebase";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import * as ImagePicker from 'expo-image-picker';
import { doc, setDoc , getDoc , deleteDoc , getDocs , updateDoc , addDoc , collection , Timestamp , query , where, Firestore , orderBy} from "firebase/firestore";

const styles = StyleSheet.create({
    container:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#0400b6',
        borderWidth: 1,
        color: '#0400b6',
        borderRadius: 30,
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutlineTexto: {
        color: '#0400b6',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
        textAlign: 'center',
    },
    itemLista:{
        marginBottom: 10,
        width: '100%',
        // maxWidth: '300px',
        padding: 6,
        maxWidth : '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 5,
        borderColor: '#0400b6',
        backgroundColor: '#ffffff',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    tituloLista:{
        fontSize: 18,
        fontWeight: 'bold',
        color: '#0400b6',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        backgroundColor: '#ececec',
        borderWidth: 0,
        // borderRadius: 30,
        marginBottom: 10,
        width: '80%',
        // maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    imagenLista:{
        height: 150,
        width: '100%',
        borderRadius: 30,
    },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
        color: '#ffffff',
      },
    logo: {
        width: 100,
        height: 187,
        marginBottom: 80,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      buttonPequeno: {
        padding: 5,
        textAlign: 'center',
        marginBottom: 10,
        width:'100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
        // borderWidth: 1,
        // borderColor: '#0400b6',
        // borderRadius: 30,
      },
      buttonPequenoTexto: {}
});

let userId = null;

const authFn = async () => {
    userId = await AsyncStorage.getItem('authToken');
}
const DATA = [];
const SUBCATEGORIAS = [];


async function pickImage()
{
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
  
  
      if (!result.canceled) {
        return result.assets[0].uri
      }

      return null

}

async function crearCategoria(data , image = null , categoriaId = null){
    try{

        if(image && image.search('https://') == -1)
        {
            const nombreImagen = 'categorias/'+categoriaId+'/cat-'+ data.nombre +'.jpg';
            const storage = getStorage();
            const storageRef = ref(storage, nombreImagen);
            
            await uploadString(storageRef, image, 'data_url').then(async (snapshot) => {
                await getDownloadURL(storageRef).then((url) => {
                    data.image = url;  
                })
            });

            // data.image = nombreImagen;
        }

        data.userId = userId;

        var newDocument = collection(Firebase, "categorias");
        if(categoriaId)
        {
            await updateDoc(doc(Firebase, "categorias", categoriaId),
                data,
            );
        }else{
            await addDoc(newDocument, data).then(() => {
                // getProductos();
            });
        }


    }catch(error){
    
    }finally{
    
    }
}

async function eliminarCat(categoriaId){
    try{

        await deleteDoc(doc(Firebase , 'categorias' , categoriaId))
        
    }catch(error){
    
    }finally{
    
    }
}

async function getCategorias()
{
    const q = query(collection(Firebase, "categorias") , where('padre' , '==' , null) , orderBy('nombre'));

    const querySnapshot = await getDocs(q);
    
    DATA.length = 0;
    
    querySnapshot.forEach((doc) => {
        DATA.push({
            id: doc.id, ...doc.data(),
        });
       
    });
}

async function getSubCategorias(padre)
{
    const q = query(collection(Firebase, "categorias") , 
                    where('padre' , '==' , padre) , 
                    orderBy('nombre'));

    const querySnapshot = await getDocs(q);
    
    SUBCATEGORIAS.length = 0;
    
    querySnapshot.forEach((doc) => {
        SUBCATEGORIAS.push({
            id: doc.id, ...doc.data(),
        });
        
    });
}





export default function CategoriasAdmin({navigation}){
   
    const [loaded , SetLoaded] = React.useState(0);
    const [editar , SetEditar] = React.useState(0);
    const [nombreCategoria , SetNombreCategoria] = React.useState('');
    const [descripcionCategoria , SetDescripcionCategoria] = React.useState('');
    const [image, SetImage] = React.useState(null);
    const [catPadre, SetCatPadre] = React.useState(null);
    const [categoriaId, SetCategoriaId] = React.useState(null);

    const [menuPos, setMenuPos] = React.useState('translateX(-100%)');
    // console.log(menuPos);
    function toggleMenu()
    {
        if(menuPos == 'translateX(-100%)')
        {
        setMenuPos('translateX(0%)');
        }else{
        setMenuPos('translateX(-100%)');
        }
    }

    
    let subCategorias = [];

    function editarCategoria(item)
    {
        getSubCategorias(item.id)

        SetNombreCategoria(item.nombre)
        SetDescripcionCategoria(item.descripcion)
        SetImage(item.image)
        SetCatPadre(item.padre)
        SetEditar(1)
        SetLoaded(0)
        SetCategoriaId(item.id)
    }

    function eliminarCategoria(itemId)
    {
        SetLoaded(0)
        eliminarCat(itemId).then(() => {
            if(!categoriaId)
            {
                SetLoaded(1)
            }
            if(categoriaId)
            {
                getSubCategorias(categoriaId).then(() => {
                    SetLoaded(1)
                })
            }
        })
    }

    function resetForm()
    {
        SUBCATEGORIAS.length = 0
        SetNombreCategoria('')
        SetDescripcionCategoria('')
        SetImage(null)
        SetCatPadre(null)
        SetCategoriaId(null)
    }
    
    function agregarSubCategoria(padre)
    {
        resetForm()
        SetCatPadre(padre)
    }

    if(loaded == 0)
    {
        getCategorias().then(() => {
            SetLoaded(1)
        });
    }

    return (
        <View style={{flex: 1  , backgroundColor: 'rgb(34,127,163)'}}>
            <ScrollView style={styles.container}>
                    <View style={{flexDirection: 'row' , alignItems: 'center', justifyContent: 'space-between' , width: '80%' , marginRight: 'auto' , marginLeft : 'auto'}}>
                        <Text style={styles.titulo}>Categorías</Text>
                        <TouchableOpacity onPress={toggleMenu}>
                            <Ionicons style={styles.heartIcon} name="menu" size={21} color="#ffffff"/>
                        </TouchableOpacity>
                    </View>
                
                    { editar ? <>
                    <TouchableOpacity style={styles.buttonPequeno} onPress={() => {
                            SetEditar(0)
                            resetForm()
                            }}>
                        <Text style={styles.buttonPequenoTexto} ><Ionicons name="arrow-back" size={20} color="#0400b6"></Ionicons> Volver al listado</Text>
                    </TouchableOpacity>

                    {/* Imagen de categoria */}
                    {/* {image && <Image source={{ uri: image }} style={{ width: '60%', height: 200 , borderRadius: 30, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />}
                    <TouchableOpacity style={styles.buttonGrande}>
                        <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((image) => {SetImage(image)})}>
                            Seleccionar Imagen
                        </Text>
                    </TouchableOpacity> */}

                    {image && <Image source={{ uri: image }} style={{ width: '80%', height: 200 , borderRadius: 30, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />}
                        <TouchableOpacity style={{textAlign: 'center', paddingTop: 10, paddingBottom: 10}}>
                            <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((image) => {SetImage(image)})}>
                                Seleccionar Imagen
                            </Text>
                        </TouchableOpacity>
                    
                    <TextInput 
                        style={styles.input}
                        onChangeText={SetNombreCategoria}
                        value={nombreCategoria}
                        placeholder="Nombre"
                    />
                    
                    <TextInput 
                        multiline
                        numberOfLines={4}
                        style={styles.input}
                        onChangeText={SetDescripcionCategoria}
                        value={descripcionCategoria}
                        placeholder="Descripción"
                    />

                    <TouchableOpacity style={styles.input} onPress={() => crearCategoria({
                        nombre : nombreCategoria,
                        descripcion: descripcionCategoria,
                        padre: catPadre

                    } , image , categoriaId).then(() => {
                        SetCatPadre(null)
                        SetEditar(0)
                        SetLoaded(0)
                    })}>
                        <Text style={styles.buttonGrandeOutlineTexto} >{ categoriaId ? 'Actualizar' : 'Crear'} Categoría</Text>
                    </TouchableOpacity>
                    { categoriaId ? <>

                        <TouchableOpacity style={styles.input} onPress={() => agregarSubCategoria(categoriaId) }>
                            <Text style={styles.buttonGrandeOutlineTexto} >Agregar SubCategoría</Text>
                        </TouchableOpacity>

                        <FlatList
                        data={SUBCATEGORIAS}
                        renderItem={({item}) => 
                            <View style={styles.itemLista}>
                                <TouchableOpacity onPress={() => editarCategoria(item)}>
                                    {/* <Image source={{ uri: item.image }} style={styles.imagenLista}/> */}
                                    <Text style={styles.tituloLista}>{item.nombre}</Text>
                                    <Text style={styles.descripcionLista}>{item.descripcion.substring(0,100)}...</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => eliminarCategoria(item.id)}>
                                    <Text>
                                        <Ionicons style={styles.trashIcon} name="trash" size={20} color="#0400b6"/>
                                    </Text>
                                </TouchableOpacity>

                            </View>
                        }
                        keyExtractor={item => item.id}
                        contentContainerStyle={styles.gridLista}
                        
                        />
                    
                    </> : <></> }
                    
                </> :  <>

                    {  }
                    <TouchableOpacity style={styles.buttonPequeno} onPress={() => {
                            SetEditar(1)
                            SetLoaded(0)
                        }}>
                        <Text><Ionicons name="add-circle" size={20} color="#0400b6"></Ionicons> Crear categoría</Text>
                    </TouchableOpacity>
                    {loaded ? <>
                        <FlatList
                        data={DATA}
                        renderItem={({item}) => 
                            <View style={styles.itemLista}>
                                <TouchableOpacity onPress={() => editarCategoria(item)}>
                                    {/* <Image source={{ uri: item.image }} style={styles.imagenLista}/> */}
                                    <Text style={styles.tituloLista}>{item.nombre}</Text>
                                    <Text style={styles.descripcionLista}>{item.descripcion.substring(0,100)}...</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => eliminarCategoria(item.id)}>
                                    <Text>
                                        <Ionicons style={styles.trashIcon} name="trash" size={20} color="#0400b6"/>
                                    </Text>
                                </TouchableOpacity>
                                
                            </View>
                        }
                        keyExtractor={item => item.id}
                        contentContainerStyle={styles.gridLista}
                        
                        />
                    </> :<></>}
                    
                </> }
               

                


            </ScrollView>

            <BotonesAdmin navigation={navigation} toggleMenu={toggleMenu} menuPos={menuPos}/>
        </View>
    );
}